import React from "react";
import { useTranslation } from "react-i18next";
import {FormGroupText, FormLanguage, FormGenDoc} from "./generator-common"

function GenerateFormBusinessPlan() {
  const [executiveSummary, setExecutiveSummary] = React.useState("");
  const [marketAnalysis, setMarketAnalysis] = React.useState("");
  const [productOrServiceDescription, setProductOrServiceDescription] = React.useState("");
  const [managementTeam, setManagementTeam] = React.useState("");
  const [swotAnalysis, setSwotAnalysis] = React.useState("");
  const [marketingStrategy, setMarketingStrategy] = React.useState("");
  const [operationsPlan, setOperationsPlan] = React.useState("");
  const [financialProjections, setFinancialProjections] = React.useState("");
  const [language, setLanguage] = React.useState("");
  const { t } = useTranslation();

  const requestDict = {
    executiveSummary: executiveSummary,
    marketAnalysis: marketAnalysis,
    productOrServiceDescription: productOrServiceDescription,
    managementTeam: managementTeam,
    swotAnalysis: swotAnalysis,
    marketingStrategy: marketingStrategy,
    operationsPlan: operationsPlan,
    financialProjections: financialProjections,
    language: language,
  };

  const setValueDict = {
    executiveSummary: setExecutiveSummary,
    marketAnalysis: setMarketAnalysis,
    productOrServiceDescription: setProductOrServiceDescription,
    managementTeam: setManagementTeam,
    swotAnalysis: setSwotAnalysis,
    marketingStrategy: setMarketingStrategy,
    operationsPlan: setOperationsPlan,
    financialProjections: setFinancialProjections,
    language: setLanguage,
  };

  const formGroups = (
    <>
      <FormGroupText value={executiveSummary} setValue={setExecutiveSummary} label={t('BusinessPlan_FieldExecutiveSummary')} tooltip={t('BusinessPlan_FieldExecutiveSummaryDesc')}/>
      <FormGroupText value={marketAnalysis} setValue={setMarketAnalysis} label={t('BusinessPlan_FieldMarketAnalysis')} tooltip={t('BusinessPlan_FieldMarketAnalysisDesc')}/>
      <FormGroupText value={productOrServiceDescription} setValue={setProductOrServiceDescription} label={t('BusinessPlan_FieldProductOrServiceDescription')} tooltip={t('BusinessPlan_FieldProductOrServiceDescriptionDesc')}/>
      <FormGroupText value={managementTeam} setValue={setManagementTeam} label={t('BusinessPlan_FieldManagementTeam')} tooltip={t('BusinessPlan_FieldManagementTeamDesc')}/>
      <FormGroupText value={swotAnalysis} setValue={setSwotAnalysis} label={t('BusinessPlan_FieldSwotAnalysis')} tooltip={t('BusinessPlan_FieldSwotAnalysisDesc')}/>
      <FormGroupText value={marketingStrategy} setValue={setMarketingStrategy} label={t('BusinessPlan_FieldMarketingStrategy')} tooltip={t('BusinessPlan_FieldMarketingStrategyDesc')}/>
      <FormGroupText value={operationsPlan} setValue={setOperationsPlan} label={t('BusinessPlan_FieldOperationsPlan')} tooltip={t('BusinessPlan_FieldOperationsPlanDesc')}/>
      <FormGroupText value={financialProjections} setValue={setFinancialProjections} label={t('BusinessPlan_FieldFinancialProjections')} tooltip={t('BusinessPlan_FieldFinancialProjectionsDesc')}/>
      <FormLanguage language={language} setLanguage={setLanguage} />
    </>
  );

  return <FormGenDoc 
    docType="BUSINESS_PLAN"
    requestDict={requestDict}
    setValueDict={setValueDict}
    ajaxUrl="ajax_business_plan"
    formTitle={t('BusinessPlan_FormTitle')}
    formGroups={formGroups} />;
}

export default GenerateFormBusinessPlan;
