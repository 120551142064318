
import React from "react";
import { useTranslation } from "react-i18next";
import {FormGroupText, FormLanguage, FormGenDoc} from "./generator-common"

function GenerateFormWeeklyReport() {
  const [audience, setAudience] = React.useState("");
  const [accomplishments, setAccomplishments] = React.useState("");
  const [challenges, setChallenges] = React.useState("");
  const [goalsForNextWeek, setGoalsForNextWeek] = React.useState("");
  const [keyPerformanceIndicators, setKeyPerformanceIndicators] = React.useState("");
  const [language, setLanguage] = React.useState("");
  const { t } = useTranslation();

  const requestDict = {
    audience: audience,
    accomplishments: accomplishments,
    challenges: challenges,
    goalsForNextWeek: goalsForNextWeek,
    keyPerformanceIndicators: keyPerformanceIndicators,
    language: language,
  };

  const setValueDict = {
    audience: setAudience,
    accomplishments: setAccomplishments,
    challenges: setChallenges,
    goalsForNextWeek: setGoalsForNextWeek,
    keyPerformanceIndicators: setKeyPerformanceIndicators,
    language: setLanguage,
  };

  const formGroups = (
    <>
      <FormGroupText value={audience} setValue={setAudience} label={t('WeeklyReport_FieldAudience')} tooltip={t('WeeklyReport_FieldAudienceDesc')}/>
      <FormGroupText value={accomplishments} setValue={setAccomplishments} label={t('WeeklyReport_FieldAccomplishments')} tooltip={t('WeeklyReport_FieldAccomplishmentsDesc')}/>
      <FormGroupText value={challenges} setValue={setChallenges} label={t('WeeklyReport_FieldChallenges')} tooltip={t('WeeklyReport_FieldChallengesDesc')}/>
      <FormGroupText value={goalsForNextWeek} setValue={setGoalsForNextWeek} label={t('WeeklyReport_FieldGoalsForNextWeek')} tooltip={t('WeeklyReport_FieldGoalsForNextWeekDesc')}/>
      <FormGroupText value={keyPerformanceIndicators} setValue={setKeyPerformanceIndicators} label={t('WeeklyReport_FieldKeyPerformanceIndicators')} tooltip={t('WeeklyReport_FieldKeyPerformanceIndicatorsDesc')}/>
      <FormLanguage language={language} setLanguage={setLanguage} />
    </>
  );

  return <FormGenDoc 
    docType="WEEKLY_REPORT"
    requestDict={requestDict}
    setValueDict={setValueDict}
    ajaxUrl="ajax_weekly_report"
    formTitle={t('WeeklyReport_FormTitle')}
    formGroups={formGroups} />;
}

export default GenerateFormWeeklyReport;
