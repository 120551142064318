import React from "react";
import { useTranslation } from "react-i18next";
import {FormGroupText, FormLanguage, FormGenDoc} from "./generator-common"

function GenerateFormFable() {
  const [fableType, setFableType] = React.useState("");
  const [protagonist, setProtagonist] = React.useState("");
  const [antagonist, setAntagonist] = React.useState("");
  const [moralLesson, setMoralLesson] = React.useState("");
  const [language, setLanguage] = React.useState("");
  const { t } = useTranslation();

  const requestDict = {
    fableType: fableType,
    protagonist: protagonist,
    antagonist: antagonist,
    moralLesson: moralLesson,
    language: language,
  };

  const setValueDict = {
    fableType: setFableType,
    protagonist: setProtagonist,
    antagonist: setAntagonist,
    moralLesson: setMoralLesson,
    language: setLanguage,
  };

  const formGroups = (
    <>
      <FormGroupText value={fableType} setValue={setFableType} label={t('Fable_FieldFableType')} tooltip={t('Fable_FieldFableTypeDesc')}/>
      <FormGroupText value={protagonist} setValue={setProtagonist} label={t('Fable_FieldProtagonist')} tooltip={t('Fable_FieldProtagonistDesc')}/>
      <FormGroupText value={antagonist} setValue={setAntagonist} label={t('Fable_FieldAntagonist')} tooltip={t('Fable_FieldAntagonistDesc')}/>
      <FormGroupText value={moralLesson} setValue={setMoralLesson} label={t('Fable_FieldMoralLesson')} tooltip={t('Fable_FieldMoralLessonDesc')}/>
      <FormLanguage language={language} setLanguage={setLanguage} />
    </>
  );

  return <FormGenDoc 
    docType="FABLE"
    requestDict={requestDict}
    setValueDict={setValueDict}
    ajaxUrl="ajax_fable"
    formTitle={t('Fable_FormTitle')}
    formGroups={formGroups} />;
}

export default GenerateFormFable;
