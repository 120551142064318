import React from "react";
import { useTranslation } from "react-i18next";
import {FormGroupText, FormLanguage, FormGenDoc} from "./generator-common"

function GenerateFormPoliticalEducation() {
  const [topic, setTopic] = React.useState("");
  const [targetAudience, setTargetAudience] = React.useState("");
  const [purpose, setPurpose] = React.useState("");
  const [contentRequirements, setContentRequirements] = React.useState("");
  const [toneAndStyle, setToneAndStyle] = React.useState("");
  const [language, setLanguage] = React.useState("");
  const { t } = useTranslation();

  const requestDict = {
    topic: topic,
    targetAudience: targetAudience,
    purpose: purpose,
    contentRequirements: contentRequirements,
    toneAndStyle: toneAndStyle,
    language: language,
  };

  const setValueDict = {
    topic: setTopic,
    targetAudience: setTargetAudience,
    purpose: setPurpose,
    contentRequirements: setContentRequirements,
    toneAndStyle: setToneAndStyle,
    language: setLanguage,
  };

  const formGroups = (
    <>
      <FormGroupText value={topic} setValue={setTopic} label={t('PoliticalEducation_FieldTopic')} tooltip={t('PoliticalEducation_FieldTopicDesc')}/>
      <FormGroupText value={targetAudience} setValue={setTargetAudience} label={t('PoliticalEducation_FieldTargetAudience')} tooltip={t('PoliticalEducation_FieldTargetAudienceDesc')}/>
      <FormGroupText value={purpose} setValue={setPurpose} label={t('PoliticalEducation_FieldPurpose')} tooltip={t('PoliticalEducation_FieldPurposeDesc')}/>
      <FormGroupText value={contentRequirements} setValue={setContentRequirements} label={t('PoliticalEducation_FieldContentRequirements')} tooltip={t('PoliticalEducation_FieldContentRequirementsDesc')}/>
      <FormGroupText value={toneAndStyle} setValue={setToneAndStyle} label={t('PoliticalEducation_FieldToneAndStyle')} tooltip={t('PoliticalEducation_FieldToneAndStyleDesc')}/>
      <FormLanguage language={language} setLanguage={setLanguage} />
    </>
  );

  return <FormGenDoc 
    docType="POLITICAL_EDUCATION"
    requestDict={requestDict}
    setValueDict={setValueDict}
    ajaxUrl="ajax_political_education"
    formTitle={t('PoliticalEducation_FormTitle')}
    formGroups={formGroups} />;
}

export default GenerateFormPoliticalEducation;
