import React from "react";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";
import { makeAjaxRequest } from "./common.js";
import avatar_1 from "./img/avatar_1.png";
import logo_small from "./img/logo_small.png";
import SignupModal from "./sa/signup-modal.js";
import LoginModal from "./sa/login-modal.js";

function NavbarUser({username}) {
  const { t } = useTranslation();

  const handleLogout = (event) => {
    event.preventDefault();

    const callback = (result) => {
      sessionStorage.removeItem("username");
      window.location.replace("/");
    }
    makeAjaxRequest("/sa/ajax_logout", {}, callback);
  };

  if (username) {
    return (
      <div className="flex-shrink-0 dropdown">
        <a href="#" className="d-block link-dark text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <img src={avatar_1} alt="avatar" width="32" height="32" className="rounded-circle"/>
        </a>
        <ul className="dropdown-menu dropdown-menu-dark dropdown-menu-end text-small shadow">
          <li className="dropdown-header"><b>{username}</b></li>
          <li><hr className="dropdown-divider"/></li>
          <li><a className="dropdown-item" href="/gendoc/doc_history">{t('Nav_DocHistory')}</a></li>
          <li><button type="submit" className="dropdown-item" onClick={handleLogout}>{t('Logout_FormTitle')}</button></li>
        </ul>
      </div>
    );
  }
  else {
    return (
      <>
      <button type="submit" className="btn btn-outline-light me-2" data-bs-toggle="modal" data-bs-target="#loginModal">{t('Login_FormTitle')}</button>
      <button type="submit" className="btn btn-warning me-2" data-bs-toggle="modal" data-bs-target="#signupModal">{t('Signup_FormTitle')}</button>
      </>
    );

  }
}

const BaseComponent = ({ children }) => {
  const { t } = useTranslation();
  const [username, setUsername] = React.useState(sessionStorage.getItem("username"));

  const handleLanguageClick = (e) => {
    // Set language cookie that expires in 365 days.
    const cookies = new Cookies();
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 365);
    cookies.set('i18next', e.target.value, { expires: expirationDate, path: '/' });
    window.location.reload(true);
  };

  React.useEffect(() => {
    if (!sessionStorage.getItem("username")) {
      const callback = (result) => {
        setUsername(result["username"]);
        sessionStorage.setItem("username", result["username"]);
      }
      makeAjaxRequest("/sa/ajax_get_user", {}, callback);
    }
  }, []);


  return (
    <>
  <nav className="navbar navbar-dark bg-dark navbar-expand-lg">
    <a className="navbar-brand" href="/"><img width="36" height="36" alt="AutoGenDoc" src={logo_small}/> AutoGenDoc</a>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <a className="nav-link active" aria-current="page" href="/">{t('Nav_Home')}</a>
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            {t('Nav_Generators')}
          </a>
          <ul className="dropdown-menu dropdown-menu-dark gap-1 p-2 rounded-3 mx-0 border-0 shadow w-220px">
            <li><a href="/gendoc/popular_science" className="dropdown-item">{t('PopularScience_FormTitle')}</a></li>
            <li><a href="/gendoc/essay" className="dropdown-item">{t('Essay_FormTitle')}</a></li>
            <li><a href="/gendoc/story" className="dropdown-item">{t('Story_FormTitle')}</a></li>
            <li><a href="/gendoc/poetry" className="dropdown-item">{t('Poetry_FormTitle')}</a></li>
            <li><a href="/gendoc/fable" className="dropdown-item">{t('Fable_FormTitle')}</a></li>
            <li><a href="/gendoc/joke" className="dropdown-item">{t('Joke_FormTitle')}</a></li>
            <li><a href="/gendoc/drama" className="dropdown-item">{t('Drama_FormTitle')}</a></li>
            <li><a href="/gendoc/biography" className="dropdown-item">{t('Biography_FormTitle')}</a></li>
            <li><a href="/gendoc/software_system_design" className="dropdown-item">{t('SoftwareSystemDesign_FormTitle')}</a></li>
            <li><a href="/gendoc/software_product_design" className="dropdown-item">{t('SoftwareProductDesign_FormTitle')}</a></li>
            <li><a href="/gendoc/general_product_design" className="dropdown-item">{t('GeneralProductDesign_FormTitle')}</a></li>
            <li><a href="/gendoc/general_engineering_design" className="dropdown-item">{t('GeneralEngineeringDesign_FormTitle')}</a></li>
            <li><a href="/gendoc/mission_statement" className="dropdown-item">{t('MissionStatement_FormTitle')}</a></li>
            <li><a href="/gendoc/resume" className="dropdown-item">{t('Resume_FormTitle')}</a></li>
            <li><a href="/gendoc/cover_letter" className="dropdown-item">{t('CoverLetter_FormTitle')}</a></li>
            <li><a href="/gendoc/personal_statement" className="dropdown-item">{t('PersonalStatement_FormTitle')}</a></li>
            <li><a href="/gendoc/advertising_proposal" className="dropdown-item">{t('AdvertisingProposal_FormTitle')}</a></li>
            <li><a href="/gendoc/research_paper" className="dropdown-item">{t('ResearchPaper_FormTitle')}</a></li>
            <li><a href="/gendoc/political_education" className="dropdown-item">{t('PoliticalEducation_FormTitle')}</a></li>
            <li><a href="/gendoc/business_plan" className="dropdown-item">{t('BusinessPlan_FormTitle')}</a></li>
            <li><a href="/gendoc/love_letter" className="dropdown-item">{t('LoveLetter_FormTitle')}</a></li>
            <li><a href="/gendoc/study_plan" className="dropdown-item">{t('StudyPlan_FormTitle')}</a></li>
            <li><a href="/gendoc/fitness_plan" className="dropdown-item">{t('FitnessPlan_FormTitle')}</a></li>
            <li><a href="/gendoc/weekly_report" className="dropdown-item">{t('WeeklyReport_FormTitle')}</a></li>
            <li><a href="/gendoc/travel_plan" className="dropdown-item">{t('TravelPlan_FormTitle')}</a></li>
            <li><a href="/gendoc/industry_research_report" className="dropdown-item">{t('IndustryResearchReport_FormTitle')}</a></li>
          </ul>
        </li>
      </ul>
    </div>
    <div className="d-flex align-items-center">
      <NavbarUser username={username} />
    </div>
    <div className="d-flex align-items-center">
      <div className="dropdown">
        <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">&#127760;</a>
        <ul className="dropdown-menu dropdown-menu-dark dropdown-menu-end" id="languagePicker">
          <li><button className="dropdown-item" onClick={handleLanguageClick} value="en">English</button></li>
          <li><button className="dropdown-item" onClick={handleLanguageClick} value="zh-CN">简体中文</button></li>
        </ul>
      </div>
    </div>
  </nav>

  <div>
    {children}
  </div>

  <SignupModal/>
  <LoginModal/>

    </>
  );
};

export default BaseComponent;
