import React from "react";
import { useTranslation } from "react-i18next";
import {FormGroupText, FormLanguage, FormGenDoc} from "./generator-common"

function GenerateFormSoftwareProductDesign() {
  const [productName, setProductName] = React.useState("");
  const [targetAudience, setTargetAudience] = React.useState("");
  const [features, setFeatures] = React.useState("");
  const [competitors, setCompetitors] = React.useState("");
  const [uniqueSellingPoint, setUniqueSellingPoint] = React.useState("");
  const [language, setLanguage] = React.useState("");
  const { t } = useTranslation();

  const requestDict = {
    productName: productName,
    targetAudience: targetAudience,
    features: features,
    competitors: competitors,
    uniqueSellingPoint: uniqueSellingPoint,
    language: language,
  };

  const setValueDict = {
    productName: setProductName,
    targetAudience: setTargetAudience,
    features: setFeatures,
    competitors: setCompetitors,
    uniqueSellingPoint: setUniqueSellingPoint,
    language: setLanguage,
  };

  const formGroups = (
    <>
      <FormGroupText value={productName} setValue={setProductName} label={t('SoftwareProductDesign_FieldProductName')} tooltip={t('SoftwareProductDesign_FieldProductNameDesc')}/>
      <FormGroupText value={targetAudience} setValue={setTargetAudience} label={t('SoftwareProductDesign_FieldTargetAudience')} tooltip={t('SoftwareProductDesign_FieldTargetAudienceDesc')}/>
      <FormGroupText value={features} setValue={setFeatures} label={t('SoftwareProductDesign_FieldFeatures')} tooltip={t('SoftwareProductDesign_FieldFeaturesDesc')}/>
      <FormGroupText value={competitors} setValue={setCompetitors} label={t('SoftwareProductDesign_FieldCompetitors')} tooltip={t('SoftwareProductDesign_FieldCompetitorsDesc')}/>
      <FormGroupText value={uniqueSellingPoint} setValue={setUniqueSellingPoint} label={t('SoftwareProductDesign_FieldUniqueSellingPoint')} tooltip={t('SoftwareProductDesign_FieldUniqueSellingPointDesc')}/>
      <FormLanguage language={language} setLanguage={setLanguage} />
    </>
  );

  return <FormGenDoc 
    docType="SOFTWARE_PRODUCT_DESIGN"
    requestDict={requestDict}
    setValueDict={setValueDict}
    ajaxUrl="ajax_software_product_design"
    formTitle={t('SoftwareProductDesign_FormTitle')}
    formGroups={formGroups} />;
}

export default GenerateFormSoftwareProductDesign;
