import React from "react";
import { useTranslation } from "react-i18next";
import {FormGroupText, FormLanguage, FormGenDoc} from "./generator-common"

function GenerateFormStory() {
  const [storyGenre, setStoryGenre] = React.useState("");
  const [storySetting, setStorySetting] = React.useState("");
  const [protagonist, setProtagonist] = React.useState("");
  const [antagonist, setAntagonist] = React.useState("");
  const [plotPoints, setPlotPoints] = React.useState("");
  const [moodOrTone, setMoodOrTone] = React.useState("");
  const [language, setLanguage] = React.useState("");
  const { t } = useTranslation();

  const requestDict = {
    storyGenre: storyGenre,
    storySetting: storySetting,
    protagonist: protagonist,
    antagonist: antagonist,
    plotPoints: plotPoints,
    moodOrTone: moodOrTone,
    language: language,
  };

  const setValueDict = {
    storyGenre: setStoryGenre,
    storySetting: setStorySetting,
    protagonist: setProtagonist,
    antagonist: setAntagonist,
    plotPoints: setPlotPoints,
    moodOrTone: setMoodOrTone,
    language: setLanguage,
  };

  const formGroups = (
    <>
      <FormGroupText value={storyGenre} setValue={setStoryGenre} label={t('Story_FieldStoryGenre')} tooltip={t('Story_FieldStoryGenreDesc')}/>
      <FormGroupText value={storySetting} setValue={setStorySetting} label={t('Story_FieldStorySetting')} tooltip={t('Story_FieldStorySettingDesc')}/>
      <FormGroupText value={protagonist} setValue={setProtagonist} label={t('Story_FieldProtagonist')} tooltip={t('Story_FieldProtagonistDesc')}/>
      <FormGroupText value={antagonist} setValue={setAntagonist} label={t('Story_FieldAntagonist')} tooltip={t('Story_FieldAntagonistDesc')}/>
      <FormGroupText value={plotPoints} setValue={setPlotPoints} label={t('Story_FieldPlotPoints')} tooltip={t('Story_FieldPlotPointsDesc')}/>
      <FormGroupText value={moodOrTone} setValue={setMoodOrTone} label={t('Story_FieldMoodOrTone')} tooltip={t('Story_FieldMoodOrToneDesc')}/>
      <FormLanguage language={language} setLanguage={setLanguage} />
    </>
  );

  return <FormGenDoc 
    docType="STORY"
    requestDict={requestDict}
    setValueDict={setValueDict}
    ajaxUrl="ajax_story"
    formTitle={t('Story_FormTitle')}
    formGroups={formGroups} />;
}

export default GenerateFormStory;
