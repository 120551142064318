
import React from "react";
import { useTranslation } from "react-i18next";
import {FormGroupText, FormLanguage, FormGenDoc} from "./generator-common"

function GenerateFormPopularScience() {
  const [topic, setTopic] = React.useState("");
  const [targetAudience, setTargetAudience] = React.useState("");
  const [length, setLength] = React.useState("");
  const [levelOfTechnicality, setLevelOfTechnicality] = React.useState("");
  const [tone, setTone] = React.useState("");
  const [sources, setSources] = React.useState("");
  const [language, setLanguage] = React.useState("");
  const { t } = useTranslation();

  const requestDict = {
    topic: topic,
    targetAudience: targetAudience,
    length: length,
    levelOfTechnicality: levelOfTechnicality,
    tone: tone,
    sources: sources,
    language: language,
  };

  const setValueDict = {
    topic: setTopic,
    targetAudience: setTargetAudience,
    length: setLength,
    levelOfTechnicality: setLevelOfTechnicality,
    tone: setTone,
    sources: setSources,
    language: setLanguage,
  };

  const formGroups = (
    <>
      <FormGroupText value={topic} setValue={setTopic} label={t('PopularScience_FieldTopic')} tooltip={t('PopularScience_FieldTopicDesc')}/>
      <FormGroupText value={targetAudience} setValue={setTargetAudience} label={t('PopularScience_FieldTargetAudience')} tooltip={t('PopularScience_FieldTargetAudienceDesc')}/>
      <FormGroupText value={length} setValue={setLength} label={t('PopularScience_FieldLength')} tooltip={t('PopularScience_FieldLengthDesc')}/>
      <FormGroupText value={levelOfTechnicality} setValue={setLevelOfTechnicality} label={t('PopularScience_FieldLevelOfTechnicality')} tooltip={t('PopularScience_FieldLevelOfTechnicalityDesc')}/>
      <FormGroupText value={tone} setValue={setTone} label={t('PopularScience_FieldTone')} tooltip={t('PopularScience_FieldToneDesc')}/>
      <FormGroupText value={sources} setValue={setSources} label={t('PopularScience_FieldSources')} tooltip={t('PopularScience_FieldSourcesDesc')}/>
      <FormLanguage language={language} setLanguage={setLanguage} />
    </>
  );

  return <FormGenDoc 
    docType="POPULAR_SCIENCE"
    requestDict={requestDict}
    setValueDict={setValueDict}
    ajaxUrl="ajax_popular_science"
    formTitle={t('PopularScience_FormTitle')}
    formGroups={formGroups} />;
}

export default GenerateFormPopularScience;
