import React from "react";
import { useTranslation } from "react-i18next";
import {FormGroupText, FormLanguage, FormGenDoc, FormGroupWorkExperience, FormGroupEducation, FormGroupSkills, FormGroupAwards} from "./generator-common"

function GenerateFormCoverLetter() {
  const [name, setName] = React.useState("");
  const [companyName, setCompanyName] = React.useState("");
  const [jobTitle, setJobTitle] = React.useState("");
  const [workExperiences, setWorkExperiences] = React.useState([]);
  const [educations, setEducations] = React.useState([]);
  const [skills, setSkills] = React.useState([]);
  const [awards, setAwards] = React.useState([]);
  const [personalQualities, setPersonalQualities] = React.useState("");
  const [language, setLanguage] = React.useState("");
  const { t } = useTranslation();

  const requestDict = {
    name: name,
    companyName: companyName,
    jobTitle: jobTitle,
    workExperiences: workExperiences,
    educations: educations,
    skills: skills,
    awards: awards,
    personalQualities: personalQualities,
    language: language,
  };

  const setValueDict = {
    name: setName,
    companyName: setCompanyName,
    jobTitle: setJobTitle,
    workExperiences: setWorkExperiences,
    educations: setEducations,
    skills: setSkills,
    awards: setAwards,
    personalQualities: setPersonalQualities,
    language: setLanguage,
  };

  const formGroups = (
    <>
      <FormGroupText value={name} setValue={setName} label={t('CoverLetter_FieldName')} tooltip={t('CoverLetter_FieldNameDesc')}/>
      <FormGroupText value={companyName} setValue={setCompanyName} label={t('CoverLetter_FieldCompanyName')} tooltip={t('CoverLetter_FieldCompanyNameDesc')}/>
      <FormGroupText value={jobTitle} setValue={setJobTitle} label={t('CoverLetter_FieldJobTitle')} tooltip={t('CoverLetter_FieldJobTitleDesc')}/>
      <FormGroupWorkExperience workExperiences={workExperiences} setWorkExperiences={setWorkExperiences} />
      <FormGroupEducation educations={educations} setEducations={setEducations} />
      <FormGroupSkills skills={skills} setSkills={setSkills} />
      <FormGroupAwards awards={awards} setAwards={setAwards} />
      <FormGroupText value={personalQualities} setValue={setPersonalQualities} label={t('CoverLetter_FieldPersonalQualities')} tooltip={t('CoverLetter_FieldPersonalQualitiesDesc')}/>
      <FormLanguage language={language} setLanguage={setLanguage} />
    </>
  );

  return <FormGenDoc 
    docType="COVER_LETTER"
    requestDict={requestDict}
    setValueDict={setValueDict}
    ajaxUrl="ajax_cover_letter"
    formTitle={t('CoverLetter_FormTitle')}
    formGroups={formGroups} />;
}

export default GenerateFormCoverLetter;
