import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import translations from './translations.js';
import BaseComponent from './base.js'
import Home from './home/home.js'
import Browse from './home/browse.js'
import DocHistory from './gendoc/doc-history.js'
import FreeFormEditing from './gendoc/freeform.js'
import GenerateFormAdvertisingProposal from './gendoc/generator-advertising-proposal.js'
import GenerateFormBiography from './gendoc/generator-biography.js'
import GenerateFormBusinessPlan from './gendoc/generator-business-plan.js'
import GenerateFormCoverLetter from './gendoc/generator-cover-letter.js'
import GenerateFormDrama from './gendoc/generator-drama.js'
import GenerateFormEssay from './gendoc/generator-essay.js'
import GenerateFormFable from './gendoc/generator-fable.js'
import GenerateFormFitnessPlan from './gendoc/generator-fitness-plan.js'
import GenerateFormGeneralEngineeringDesign from './gendoc/generator-general-engineering-design.js'
import GenerateFormGeneralProductDesign from './gendoc/generator-general-product-design.js'
import GenerateFormIndustryResearchReport from './gendoc/generator-industry-research-report.js'
import GenerateFormJoke from './gendoc/generator-joke.js'
import GenerateFormLoveLetter from './gendoc/generator-love-letter.js'
import GenerateFormMissionStatement from './gendoc/generator-mission-statement.js'
import GenerateFormPersonalStatement from './gendoc/generator-personal-statement.js'
import GenerateFormPoetry from './gendoc/generator-poetry.js'
import GenerateFormPoliticalEducation from './gendoc/generator-political-education.js'
import GenerateFormPopularScience from './gendoc/generator-popular-science.js'
import GenerateFormResearchPaper from './gendoc/generator-research-paper.js'
import GenerateFormResume from './gendoc/generator-resume.js'
import GenerateFormSoftwareProductDesign from './gendoc/generator-software-product-design.js'
import GenerateFormSoftwareSystemDesign from './gendoc/generator-software-system-design.js'
import GenerateFormStory from './gendoc/generator-story.js'
import GenerateFormStudyPlan from './gendoc/generator-study-plan.js'
import GenerateFormTravelPlan from './gendoc/generator-travel-plan.js'
import GenerateFormWeeklyReport from './gendoc/generator-weekly-report.js'
import VerifyEmail from './sa/verify-email.js'

function initI18n() {
  i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18n.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: translations,
    fallbackLng: "en",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18n.com/translation-function/interpolation#unescape
    },
  });
}

function App() {
  initI18n();

  return (
    <Router>
      <BaseComponent>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home/browse" element={<Browse />} />
          <Route path="/gendoc/doc_history" element={<DocHistory />} />
          <Route path="/gendoc/freeform" element={<FreeFormEditing />} />
          <Route path="/gendoc/advertising_proposal" element={<GenerateFormAdvertisingProposal />} />
          <Route path="/gendoc/biography" element={<GenerateFormBiography />} />
          <Route path="/gendoc/business_plan" element={<GenerateFormBusinessPlan />} />
          <Route path="/gendoc/cover_letter" element={<GenerateFormCoverLetter />} />
          <Route path="/gendoc/drama" element={<GenerateFormDrama />} />
          <Route path="/gendoc/essay" element={<GenerateFormEssay />} />
          <Route path="/gendoc/fable" element={<GenerateFormFable />} />
          <Route path="/gendoc/fitness_plan" element={<GenerateFormFitnessPlan />} />
          <Route path="/gendoc/general_engineering_design" element={<GenerateFormGeneralEngineeringDesign />} />
          <Route path="/gendoc/general_product_design" element={<GenerateFormGeneralProductDesign />} />
          <Route path="/gendoc/industry_research_report" element={<GenerateFormIndustryResearchReport />} />
          <Route path="/gendoc/joke" element={<GenerateFormJoke />} />
          <Route path="/gendoc/love_letter" element={<GenerateFormLoveLetter />} />
          <Route path="/gendoc/mission_statement" element={<GenerateFormMissionStatement />} />
          <Route path="/gendoc/personal_statement" element={<GenerateFormPersonalStatement />} />
          <Route path="/gendoc/poetry" element={<GenerateFormPoetry />} />
          <Route path="/gendoc/political_education" element={<GenerateFormPoliticalEducation />} />
          <Route path="/gendoc/popular_science" element={<GenerateFormPopularScience />} />
          <Route path="/gendoc/research_paper" element={<GenerateFormResearchPaper />} />
          <Route path="/gendoc/resume" element={<GenerateFormResume />} />
          <Route path="/gendoc/software_product_design" element={<GenerateFormSoftwareProductDesign />} />
          <Route path="/gendoc/software_system_design" element={<GenerateFormSoftwareSystemDesign />} />
          <Route path="/gendoc/story" element={<GenerateFormStory />} />
          <Route path="/gendoc/study_plan" element={<GenerateFormStudyPlan />} />
          <Route path="/gendoc/travel_plan" element={<GenerateFormTravelPlan />} />
          <Route path="/gendoc/weekly_report" element={<GenerateFormWeeklyReport />} />
          <Route path="/sa/verify/:uidb64/:token" element={<VerifyEmail />} />
        </Routes>
      </BaseComponent>
    </Router>
  );
}

export default App;
