import React from "react";
import { useTranslation } from "react-i18next";
import {FormGroupText, FormLanguage, FormGenDoc} from "./generator-common"

function GenerateFormJoke() {
  const [jokeType, setJokeType] = React.useState("");
  const [jokeSetup, setJokeSetup] = React.useState("");
  const [jokePunchline, setJokePunchline] = React.useState("");
  const [jokeContext, setJokeContext] = React.useState("");
  const [language, setLanguage] = React.useState("");
  const { t } = useTranslation();

  const requestDict = {
    jokeType: jokeType,
    jokeSetup: jokeSetup,
    jokePunchline: jokePunchline,
    jokeContext: jokeContext,
    language: language,
  };

  const setValueDict = {
    jokeType: setJokeType,
    jokeSetup: setJokeSetup,
    jokePunchline: setJokePunchline,
    jokeContext: setJokeContext,
    language: setLanguage,
  };

  const formGroups = (
    <>
      <FormGroupText value={jokeType} setValue={setJokeType} label={t('Joke_FieldJokeType')} tooltip={t('Joke_FieldJokeTypeDesc')}/>
      <FormGroupText value={jokeSetup} setValue={setJokeSetup} label={t('Joke_FieldJokeSetup')} tooltip={t('Joke_FieldJokeSetupDesc')}/>
      <FormGroupText value={jokePunchline} setValue={setJokePunchline} label={t('Joke_FieldJokePunchline')} tooltip={t('Joke_FieldJokePunchlineDesc')}/>
      <FormGroupText value={jokeContext} setValue={setJokeContext} label={t('Joke_FieldJokeContext')} tooltip={t('Joke_FieldJokeContextDesc')}/>
      <FormLanguage language={language} setLanguage={setLanguage} />
    </>
  );

  return <FormGenDoc 
    docType="JOKE"
    requestDict={requestDict}
    setValueDict={setValueDict}
    ajaxUrl="ajax_joke"
    formTitle={t('Joke_FormTitle')}
    formGroups={formGroups} />;
}

export default GenerateFormJoke;
