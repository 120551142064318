import React from "react";
import { useTranslation } from "react-i18next";
import {FormGroupText, FormLanguage, FormGenDoc} from "./generator-common"

function GenerateFormStudyPlan() {
  const [educationLevel, setEducationLevel] = React.useState("");
  const [academicGoals, setAcademicGoals] = React.useState("");
  const [learningStyle, setLearningStyle] = React.useState("");
  const [studySchedule, setStudySchedule] = React.useState("");
  const [studyMaterials, setStudyMaterials] = React.useState("");
  const [language, setLanguage] = React.useState("");
  const { t } = useTranslation();

  const requestDict = {
    educationLevel: educationLevel,
    academicGoals: academicGoals,
    learningStyle: learningStyle,
    studySchedule: studySchedule,
    studyMaterials: studyMaterials,
    language: language,
  };

  const setValueDict = {
    educationLevel: setEducationLevel,
    academicGoals: setAcademicGoals,
    learningStyle: setLearningStyle,
    studySchedule: setStudySchedule,
    studyMaterials: setStudyMaterials,
    language: setLanguage,
  };

  const formGroups = (
    <>
      <FormGroupText value={educationLevel} setValue={setEducationLevel} label={t('StudyPlan_FieldEducationLevel')} tooltip={t('StudyPlan_FieldEducationLevelDesc')}/>
      <FormGroupText value={academicGoals} setValue={setAcademicGoals} label={t('StudyPlan_FieldAcademicGoals')} tooltip={t('StudyPlan_FieldAcademicGoalsDesc')}/>
      <FormGroupText value={learningStyle} setValue={setLearningStyle} label={t('StudyPlan_FieldLearningStyle')} tooltip={t('StudyPlan_FieldLearningStyleDesc')}/>
      <FormGroupText value={studySchedule} setValue={setStudySchedule} label={t('StudyPlan_FieldStudySchedule')} tooltip={t('StudyPlan_FieldStudyScheduleDesc')}/>
      <FormGroupText value={studyMaterials} setValue={setStudyMaterials} label={t('StudyPlan_FieldStudyMaterials')} tooltip={t('StudyPlan_FieldStudyMaterialsDesc')}/>
      <FormLanguage language={language} setLanguage={setLanguage} />
    </>
  );

  return <FormGenDoc 
    docType="STUDY_PLAN"
    requestDict={requestDict}
    setValueDict={setValueDict}
    ajaxUrl="ajax_study_plan"
    formTitle={t('StudyPlan_FormTitle')}
    formGroups={formGroups} />;
}

export default GenerateFormStudyPlan;
