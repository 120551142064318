import React from "react";
import { useTranslation } from "react-i18next";
import { makeAjaxRequest } from "../common.js";

function ExpandableText({fullText}) {
  const [isExpanded, setIsExpanded] = React.useState(false);

  function handleExpand(e) {
    e.preventDefault();
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  }
  const shortText = fullText.substring(0, 80) + "...";

  return (
    <>
      {isExpanded ? fullText : shortText}
      <a href="#" class="link-info" onClick={(e) => handleExpand(e)}>
        <i class={`bi ${isExpanded ? 'bi-chevron-up' : 'bi-chevron-down'}`}></i>
      </a>
    </>
  );
}

function DocHistory() {
  const { t } = useTranslation();
  const [docList, setDocList] = React.useState([]);
  const [deleteExternalDocId, setDeleteExternalDocId] = React.useState("");
  const [deleteDocContent, setDeleteDocContent] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const docTypeEnumToTranslation = {
    "ADVERTISING_PROPOSAL": t('AdvertisingProposal_FormTitle'),
    "BIOGRAPHY": t('Biography_FormTitle'),
    "BUSINESS_PLAN": t('BusinessPlan_FormTitle'),
    "COVER_LETTER": t('CoverLetter_FormTitle'),
    "DRAMA": t('Drama_FormTitle'),
    "ESSAY": t('Essay_FormTitle'),
    "FABLE": t('Fable_FormTitle'),
    "FITNESS_PLAN": t('FitnessPlan_FormTitle'),
    "GENERAL_ENGINEERING_DESIGN": t('GeneralEngineeringDesign_FormTitle'),
    "GENERAL_PRODUCT_DESIGN": t('GeneralProductDesign_FormTitle'),
    "INDUSTRY_RESEARCH_REPORT": t('IndustryResearchReport_FormTitle'),
    "JOKE": t('Joke_FormTitle'),
    "LOVE_LETTER": t('LoveLetter_FormTitle'),
    "MISSION_STATEMENT": t('MissionStatement_FormTitle'),
    "PERSONAL_STATEMENT": t('PersonalStatement_FormTitle'),
    "POETRY": t('Poetry_FormTitle'),
    "POLITICAL_EDUCATION": t('PoliticalEducation_FormTitle'),
    "POPULAR_SCIENCE": t('PopularScience_FormTitle'),
    "RESEARCH_PAPER": t('ResearchPaper_FormTitle'),
    "RESUME": t('Resume_FormTitle'),
    "SOFTWARE_PRODUCT_DESIGN": t('SoftwareProductDesign_FormTitle'),
    "SOFTWARE_SYSTEM_DESIGN": t('SoftwareSystemDesign_FormTitle'),
    "STORY": t('Story_FormTitle'),
    "STUDY_PLAN": t('StudyPlan_FormTitle'),
    "TRAVEL_PLAN": t('TravelPlan_FormTitle'),
    "WEEKLY_REPORT": t('WeeklyReport_FormTitle'),
  };

  const docTypeEnumToURL = {
    "ADVERTISING_PROPOSAL": "advertising_proposal",
    "BIOGRAPHY": "biography",
    "BUSINESS_PLAN": "business_plan",
    "COVER_LETTER": "cover_letter",
    "DRAMA": "drama",
    "ESSAY": "essay",
    "FABLE": "fable",
    "FITNESS_PLAN": "fitness_plan",
    "GENERAL_ENGINEERING_DESIGN": "general_engineering_design",
    "GENERAL_PRODUCT_DESIGN": "general_product_design",
    "INDUSTRY_RESEARCH_REPORT": "industry_research_report",
    "JOKE": "joke",
    "LOVE_LETTER": "love_letter",
    "MISSION_STATEMENT": "mission_statement",
    "PERSONAL_STATEMENT": "personal_statement",
    "POETRY": "poetry",
    "POLITICAL_EDUCATION": "political_education",
    "POPULAR_SCIENCE": "popular_science",
    "RESEARCH_PAPER": "research_paper",
    "RESUME": "resume",
    "SOFTWARE_PRODUCT_DESIGN": "software_product_design",
    "SOFTWARE_SYSTEM_DESIGN": "software_system_design",
    "STORY": "story",
    "STUDY_PLAN": "study_plan",
    "TRAVEL_PLAN": "travel_plan",
    "WEEKLY_REPORT": "weekly_report",
  };

  const handleOpen = (docType, externalDocId) => {
    const baseURL = "/gendoc/" + docTypeEnumToURL[docType];
    const params = new URLSearchParams();
    params.append("external_doc_id", externalDocId);
    const docURL = `${baseURL}?${params.toString()}`;
    window.location.href = docURL;
  };
  const handleDelete = (externalDocId) => {
    const callback = (result) => {
      let index = docList.findIndex(obj => obj.external_doc_id === externalDocId);
      if (index !== -1) {
        docList.splice(index, 1);
      }
      setDocList([...docList]);
    };
    const ajaxRequest = {
      externalDocId: externalDocId,
    };
    makeAjaxRequest("/gendoc/ajax_delete_doc", ajaxRequest, callback, setErrorMessage, setIsLoading);
  };

  React.useEffect(() => {
    const callback = (result) => {
      setDocList(result["docList"]);
    }
    makeAjaxRequest("/gendoc/ajax_doc_history", {}, callback, setErrorMessage, setIsLoading);
  }, []);

  return(
    <div className="container">
      <h2>{t('DocHistory_FormTitle')}</h2>
      {errorMessage &&
        <div className="alert alert-danger" role="alert">{errorMessage}</div>
      }
      <div class="table-responsive mx-n1 px-1 scrollbar">
        <table class="table table-striped table-hover table-sm mb-0 border-top border-200">
          <thead>
            <tr class="text-center">
              <th scope="col" style={{"min-width": "40px"}}>{t('DocHistory_FormType')}</th>
              <th scope="col">{t('DocHistory_FormContent')}</th>
              <th scope="col" style={{"min-width": "80px"}}>{t('DocHistory_FormCreateTime')}</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
          {isLoading && (
            <div id="loading-root" className="spinner-border text-primary" role="status"></div>
          )}
          {docList.map((doc, index) => (
            <tr onClick={() => handleOpen(doc["doc_type"], doc["external_doc_id"])} style={{ cursor: 'pointer' }}>
              <td class="text-center">{docTypeEnumToTranslation[doc["doc_type"]]}</td>
              <td><ExpandableText fullText={doc["generated_doc"]}/></td>
              <td class="text-center">
                {new Date(doc["created_at"]).toLocaleString(undefined, {dateStyle: "short"})} 
                <br/>
                {new Date(doc["created_at"]).toLocaleString(undefined, {timeStyle: "short"})}
              </td>
              <td>
                <a href="#" data-bs-toggle="modal" data-bs-target="#confirmDeletionModal" class="link-danger"
                onClick={(e) => {e.stopPropagation(); setDeleteExternalDocId(doc["external_doc_id"]); setDeleteDocContent(doc["generated_doc"]); }}>
                  <i class="bi bi-trash3"></i>
                </a>
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>

      <div class="modal fade" id="confirmDeletionModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="staticBackdropLabel">{t('DocHistory_FormConfirmDeletion')}</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              {deleteDocContent}
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{t('DocHistory_ButtonClose')}</button>
              <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
               onClick={() => handleDelete(deleteExternalDocId)}>{t('DocHistory_ButtonDelete')}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
}

export default DocHistory;
