import React from 'react';
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import logo_small from '../img/logo_small.png';
import WeChat_testing_group from '../img/WeChat_testing_group.jpg';
import screenshot_story_lower_en from '../img/screenshot_story_lower_en.png';
import OpenAI_Logo from '../img/OpenAI_Logo.svg';
import screenshot_story_upper_en from '../img/screenshot_story_upper_en.png';
import multi_language_demo from '../img/multi_language_demo.png';

function Home() {
  const { t } = useTranslation();

  return (
  <>
<div class="px-4 py-5 text-center" style={{"background-image": "linear-gradient(0deg, rgb(173,252,234,0.1), rgba(192,229,246,0.5))"}}>
  <img class="d-block mx-auto mb-4" src={logo_small} alt="" width="72" height="72"/>
  <h1 class="display-5 fw-bold">{t('Home_Title')}</h1>
  <div class="col-lg-6 mx-auto">
    <p class="lead mb-4">{t('Home_Lead')}</p>
    <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
      <a href="/home/browse" class="btn btn-primary btn-lg px-4 gap-3"><i class="bi bi-card-list"></i> {t('Home_ButtonBrowse')}</a>
    </div>
  </div>
</div>
{i18n.language === "cn" || i18n.language === "zh" || i18n.language === "zh-CN" ? 
<div class="container my-5">
  <div class="row p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg text-center">
    <div class="col-lg-7 p-3 p-lg-5 pt-lg-3">
      <h1 class="display-6 fw-bold lh-1">加入内测</h1>
      <p class="lead">欢迎加入微信AutoGenDoc内测群，并提出您宝贵的意见和建议！</p>
    </div>
    <div class="col-lg-4 mb-4 mb-lg-3">
      <img class="rounded-lg-3" src={WeChat_testing_group} alt="" width="180"/>
    </div>
  </div>
</div>
: null
}
<div class="container my-5">
  <div class="row p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg">
    <div class="col-lg-7 p-3 p-lg-5 pt-lg-3">
      <h1 class="display-6 fw-bold lh-1">{t('Home_SectionCapability')}</h1>
      <p class="lead">{t('Home_SectionCapabilityDesc')}</p>
    </div>
    <div class="col-lg-4 offset-lg-1 p-0 overflow-hidden shadow-lg">
        <img class="rounded-lg-3" src={screenshot_story_lower_en} alt="" width="720"/>
    </div>
  </div>
</div>
<div class="container my-5">
  <div class="row p-4 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg">
    <div class="col-lg-4 p-3">
        <img class="rounded-lg-3" src={OpenAI_Logo} alt="" width="250"/>
    </div>
    <div class="col-lg-7 p-3 p-lg-5 pt-lg-3">
      <h1 class="display-6 fw-bold lh-1">{t('Home_SectionAI')}</h1>
      <p class="lead">{t('Home_SectionAIDesc')}</p>
    </div>
  </div>
</div>
<div class="container my-5">
  <div class="row p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg">
    <div class="col-lg-7 p-3 p-lg-5 pt-lg-3">
      <h1 class="display-6 fw-bold lh-1">{t('Home_SectionCustomize')}</h1>
      <p class="lead">{t('Home_SectionCustomizeDesc')}</p>
    </div>
    <div class="col-lg-4 offset-lg-1 p-0 overflow-hidden shadow-lg">
        <img class="rounded-lg-3" src={screenshot_story_upper_en} alt="" width="720"/>
    </div>
  </div>
</div>
<div class="container my-5">
  <div class="row p-4 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg">
    <div class="col-lg-5 p-0 overflow-hidden shadow-lg">
        <img class="rounded-lg-3" src={multi_language_demo} alt="" width="450"/>
    </div>
    <div class="col-lg-7 p-3 p-lg-5 pt-lg-3">
      <h1 class="display-6 fw-bold lh-1">{t('Home_SectionMultilingual')}</h1>
      <p class="lead">{t('Home_SectionMultilingualDesc')}</p>
    </div>
  </div>
</div>
<div class="container my-5">
  <div class="row p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg text-center">
    <div class="p-3 p-lg-5 pt-lg-3">
      <h1 class="display-6 fw-bold lh-1">{t('Home_SectionFree')}</h1>
      <p class="lead">{t('Home_SectionFreeDesc')}</p>
      <div class="d-grid gap-2 d-md-flex justify-content-md-center mb-4 mb-lg-3">
        <button type="submit" class="btn btn-primary btn-lg px-4 me-md-2 fw-bold" data-bs-toggle="modal" data-bs-target="#signupModal"><i class="bi bi-r-circle"></i> {t('Signup_FormTitle')}</button>
      </div>
    </div>
  </div>
</div>
  </>
    );
}

export default Home;