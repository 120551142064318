import React from "react";
import { useTranslation } from "react-i18next";
import {FormGroupText, FormLanguage, FormGenDoc} from "./generator-common"

function GenerateFormAdvertisingProposal() {
  const [companyInformation, setCompanyInformation] = React.useState("");
  const [targetAudience, setTargetAudience] = React.useState("");
  const [campaignGoals, setCampaignGoals] = React.useState("");
  const [proposedStrategies, setProposedStrategies] = React.useState("");
  const [callToAction, setCallToAction] = React.useState("");
  const [language, setLanguage] = React.useState("");
  const { t } = useTranslation();

  const requestDict = {
    companyInformation: companyInformation,
    targetAudience: targetAudience,
    campaignGoals: campaignGoals,
    proposedStrategies: proposedStrategies,
    callToAction: callToAction,
    language: language,
  };

  const setValueDict = {
    companyInformation: setCompanyInformation,
    targetAudience: setTargetAudience,
    campaignGoals: setCampaignGoals,
    proposedStrategies: setProposedStrategies,
    callToAction: setCallToAction,
    language: setLanguage,
  };

  const formGroups = (
    <>
      <FormGroupText value={companyInformation} setValue={setCompanyInformation} label={t('AdvertisingProposal_FieldCompanyInformation')} tooltip={t('AdvertisingProposal_FieldCompanyInformationDesc')}/>
      <FormGroupText value={targetAudience} setValue={setTargetAudience} label={t('AdvertisingProposal_FieldTargetAudience')} tooltip={t('AdvertisingProposal_FieldTargetAudienceDesc')}/>
      <FormGroupText value={campaignGoals} setValue={setCampaignGoals} label={t('AdvertisingProposal_FieldCampaignGoals')} tooltip={t('AdvertisingProposal_FieldCampaignGoalsDesc')}/>
      <FormGroupText value={proposedStrategies} setValue={setProposedStrategies} label={t('AdvertisingProposal_FieldProposedStrategies')} tooltip={t('AdvertisingProposal_FieldProposedStrategiesDesc')}/>
      <FormGroupText value={callToAction} setValue={setCallToAction} label={t('AdvertisingProposal_FieldCallToAction')} tooltip={t('AdvertisingProposal_FieldCallToActionDesc')}/>
      <FormLanguage language={language} setLanguage={setLanguage} />
    </>
  );

  return <FormGenDoc 
    docType="ADVERTISING_PROPOSAL"
    requestDict={requestDict}
    setValueDict={setValueDict}
    ajaxUrl="ajax_advertising_proposal"
    formTitle={t('AdvertisingProposal_FormTitle')}
    formGroups={formGroups} />;
}

export default GenerateFormAdvertisingProposal;
