import React from "react";
import { useTranslation } from "react-i18next";
import {FormGroupText, FormLanguage, FormGenDoc} from "./generator-common"

function GenerateFormLoveLetter() {
  const [recipientName, setRecipientName] = React.useState("");
  const [relationship, setRelationship] = React.useState("");
  const [romanticMemories, setRomanticMemories] = React.useState("");
  const [compliments, setCompliments] = React.useState("");
  const [hopesAndDreams, setHopesAndDreams] = React.useState("");
  const [language, setLanguage] = React.useState("");
  const { t } = useTranslation();

  const requestDict = {
    recipientName: recipientName,
    relationship: relationship,
    romanticMemories: romanticMemories,
    compliments: compliments,
    hopesAndDreams: hopesAndDreams,
    language: language,
  };

  const setValueDict = {
    recipientName: setRecipientName,
    relationship: setRelationship,
    romanticMemories: setRomanticMemories,
    compliments: setCompliments,
    hopesAndDreams: setHopesAndDreams,
    language: setLanguage,
  };

  const formGroups = (
    <>
      <FormGroupText value={recipientName} setValue={setRecipientName} label={t('LoveLetter_FieldRecipientName')} tooltip={t('LoveLetter_FieldRecipientNameDesc')}/>
      <FormGroupText value={relationship} setValue={setRelationship} label={t('LoveLetter_FieldRelationship')} tooltip={t('LoveLetter_FieldRelationshipDesc')}/>
      <FormGroupText value={romanticMemories} setValue={setRomanticMemories} label={t('LoveLetter_FieldRomanticMemories')} tooltip={t('LoveLetter_FieldRomanticMemoriesDesc')}/>
      <FormGroupText value={compliments} setValue={setCompliments} label={t('LoveLetter_FieldCompliments')} tooltip={t('LoveLetter_FieldComplimentsDesc')}/>
      <FormGroupText value={hopesAndDreams} setValue={setHopesAndDreams} label={t('LoveLetter_FieldHopesAndDreams')} tooltip={t('LoveLetter_FieldHopesAndDreamsDesc')}/>
      <FormLanguage language={language} setLanguage={setLanguage} />
    </>
  );

  return <FormGenDoc 
    docType="LOVE_LETTER"
    requestDict={requestDict}
    setValueDict={setValueDict}
    ajaxUrl="ajax_love_letter"
    formTitle={t('LoveLetter_FormTitle')}
    formGroups={formGroups} />;
}

export default GenerateFormLoveLetter;
