import React from "react";
import { useTranslation } from "react-i18next";
import {FormLanguage, addAlert} from "./generator-common"
import Cookies from 'universal-cookie';
import { makeAjaxRequest, makeAjaxRawRequest } from "../common.js";

function FreeformToolbarButtons({handleModify}) {
  const { t } = useTranslation();
  return (
    <>
      <button type="button" className="btn btn-sm btn-outline-secondary mx-2" onClick={() => handleModify("SUMMARIZE")}>
        <i class="bi bi-list-stars"></i> {t('Common_Summarize')}
      </button>
      <div className="dropdown btn-group" role="group">
        <button type="button" className="btn btn-sm btn-outline-secondary dropdown-toggle mx-2" data-bs-toggle="dropdown" aria-expanded="false">
          <i class="bi bi-file-earmark-word"></i> {t('Common_Words')}
        </button>
        <ul className="dropdown-menu gap-1 p-2 rounded-3 mx-0 shadow w-220px">
          <li><button className="dropdown-item rounded-2" onClick={() => handleModify("MORE_WORDS")}>
            <i class="bi bi-file-earmark-plus"></i> {t('Common_MoreWords')}
          </button></li>
          <li><button className="dropdown-item rounded-2" onClick={() => handleModify("LESS_WORDS")}>
            <i class="bi bi-file-earmark-minus"></i> {t('Common_LessWords')}
          </button></li>
        </ul>
      </div>
      <div className="dropdown btn-group" role="group">
        <button type="button" className="btn btn-sm btn-outline-secondary dropdown-toggle mx-2" data-bs-toggle="dropdown" aria-expanded="false">
          <i class="bi bi-chat-left-dots"></i> {t('Common_Tone')}
        </button>
        <ul className="dropdown-menu gap-1 p-2 rounded-3 mx-0 shadow w-220px">
          <li><button className="dropdown-item rounded-2" onClick={() => handleModify("TONE_PROFESSIONAL")}>
            <i class="bi bi-chat-left"></i> {t('Common_ToneProfessional')}
          </button></li>
          <li><button className="dropdown-item rounded-2" onClick={() => handleModify("TONE_CONFIDENT")}>
            <i class="bi bi-chat-left"></i> {t('Common_ToneConfident')}
          </button></li>
          <li><button className="dropdown-item rounded-2" onClick={() => handleModify("TONE_EMOTIONAL")}>
            <i class="bi bi-chat-left"></i> {t('Common_ToneEmotional')}
          </button></li>
          <li><button className="dropdown-item rounded-2" onClick={() => handleModify("TONE_EMPATHETIC")}>
            <i class="bi bi-chat-left"></i> {t('Common_ToneEmpathetic')}
          </button></li>
          <li><button className="dropdown-item rounded-2" onClick={() => handleModify("TONE_FORMAL")}>
            <i class="bi bi-chat-left"></i> {t('Common_ToneFormal')}
          </button></li>
          <li><button className="dropdown-item rounded-2" onClick={() => handleModify("TONE_FRIENDLY")}>
            <i class="bi bi-chat-left"></i> {t('Common_ToneFriendly')}
          </button></li>
          <li><button className="dropdown-item rounded-2" onClick={() => handleModify("TONE_HUMOROUS")}>
            <i class="bi bi-chat-left"></i> {t('Common_ToneHumorous')}
          </button></li>
          <li><button className="dropdown-item rounded-2" onClick={() => handleModify("TONE_OPTIMISTIC")}>
            <i class="bi bi-chat-left"></i> {t('Common_ToneOptimistic')}
          </button></li>
          <li><button className="dropdown-item rounded-2" onClick={() => handleModify("TONE_PESSIMISTIC")}>
            <i class="bi bi-chat-left"></i> {t('Common_TonePessimistic')}
          </button></li>
          <li><button className="dropdown-item rounded-2" onClick={() => handleModify("TONE_SARCASTIC")}>
            <i class="bi bi-chat-left"></i> {t('Common_ToneSarcastic')}
          </button></li>
        </ul>
      </div>
    </>
  );
}

function FreeFormEditing() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [selectedText, setSelectedText] = React.useState("");
  const [toolbarVisible, setToolbarVisible] = React.useState(false);
  const [toolbarPosition, setToolbarPosition] = React.useState({ top: 0, left: 0 });
  const [suggestedTextVisible, setSuggestedTextVisible] = React.useState(false);
  const [suggestedText, setSuggestedText] = React.useState("");
  const [commandVisible, setCommandVisible] = React.useState(false);
  const [command, setCommand] = React.useState("");
  const docContentRef = React.useRef(null);
  const suggestedTextAlertRef = React.useRef(null);
  const [language, setLanguage] = React.useState("");

  function handleMouseUp(event) {
    setCommandVisible(false);

    const selection = window.getSelection();
    const selectedText = selection.toString().trim();
    const range = selection.getRangeAt(0);
    const rect = range.getBoundingClientRect();
    const position = {
      top: rect.top + window.pageYOffset - 40,
      left: Math.min(window.innerWidth - 300, rect.left + window.pageXOffset + rect.width / 2),
    };

    setSelectedText(selectedText);
    setToolbarVisible(selectedText.length > 0);
    setToolbarPosition(position);
  }

  const handleCommandButton = () => {
    setToolbarVisible(false);
    setSuggestedTextVisible(false);
    setCommandVisible(true);
  };

  const handleCommandInputKeyDown = (event) => {
    if (event.key === "Enter") {
      setCommandVisible(false);
      setSuggestedTextVisible(false);
      const commandRequestDict = {
        'command': command,
        'language': language,
        'text': selectedText,
      };
      const callback = (result) => {
        setSuggestedText(result["resultText"]);
        setSuggestedTextVisible(true);
      }
      makeAjaxRequest("/gendoc/ajax_command", commandRequestDict, callback, setErrorMessage, setIsLoading);
    }
  };

  const handleModify = (action) => {
    setToolbarVisible(false);
    setSuggestedTextVisible(false);

    var textToModify = "";
    if (selectedText) {
      textToModify = selectedText;
    } else {
      textToModify = docContentRef.current.innerText;
    }
    const modifyRequestDict = {
      'action': action,
      'text': textToModify,
      'language': language,
      'only_modify': true,
    };
    const callback = (result) => {
      setSuggestedText(result["resultText"]);
      setSuggestedTextVisible(true);
    }
    makeAjaxRequest("/gendoc/ajax_modify", modifyRequestDict, callback, setErrorMessage, setIsLoading);
  };

  const handleInsert = () => {
    const selection = window.getSelection();
    if (selection.focusNode.parentElement.closest("#freeform-text-box")) {
      const range = selection.getRangeAt(0);
      const selectedText = range.toString();

      const newNode = document.createElement("span");
      newNode.innerHTML = suggestedText;
      const range2 = range.cloneRange();
      range2.collapse(false); // collapse the range to the end of selection
      range2.insertNode(newNode);      
      setSuggestedTextVisible(false);
      return;
    }
    addAlert(t('Freeform_AlertInsertSelection'), 'warning', suggestedTextAlertRef);
  };

  const handleReplace = () => {
    const selection = window.getSelection();
    if (selection.focusNode.parentElement.closest("#freeform-text-box")) {
      const range = selection.getRangeAt(0);
      const selectedText = range.toString();

      if (selectedText) {
        range.deleteContents();
        const newNode = document.createElement("span");
        newNode.innerHTML = suggestedText;
        range.insertNode(newNode);
        range.setStartAfter(newNode);
        range.collapse(true);
        selection.removeAllRanges();
        selection.addRange(range);
        setSuggestedTextVisible(false);
        return;
      }
    }
    addAlert(t('Freeform_AlertReplaceSelection'), 'warning', suggestedTextAlertRef);
  };

  const handleDiscard = () => {
    setSuggestedTextVisible(false);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    const callback = (result) => {
      docContentRef.current.innerHTML = result["resultText"];
    }
    makeAjaxRawRequest("/gendoc/ajax_import_file", formData, callback, setErrorMessage, setIsLoading);
  };

  const handleFileDownload = (event, exportType) => {
    event.preventDefault();
    const cookies = new Cookies();
    const csrftoken = cookies.get('csrftoken');

    const exportRequestDict = {
      'content': docContentRef.current.innerHTML,
      'export_type': exportType,
    };

    const backendAjaxUrl = process.env.REACT_APP_BACKEND_API_URL + '/gendoc/ajax_export_file';
    fetch(backendAjaxUrl, {
      method: 'POST',
      body: JSON.stringify(exportRequestDict),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken,
      }
    })
    .then(response => response.blob())
    .then(blob => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      var downloadFilename = 'autogendoc_output';
      if (exportType === 'PDF')
        downloadFilename += '.pdf'
      else if (exportType === 'WORD')
        downloadFilename += '.docx'
      a.download = downloadFilename;
      document.body.appendChild(a);
      a.click();
      a.remove();
      URL.revokeObjectURL(url);
    })
    .catch(error => console.error(error));
  }

  return (
    <div className="container">
      <h2>{t('Freeform_FormTitle')}</h2>
      {errorMessage &&
        <div className="alert alert-danger" role="alert">{errorMessage}</div>
      }
      <FormLanguage language={language} setLanguage={setLanguage} />
      {isLoading && (
        <div id="loading-root" className="spinner-border text-primary" role="status"></div>
      )}
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <div className="btn-toolbar mb-2 mb-md-0">
          <FreeformToolbarButtons handleModify={handleModify}/>
          <div className="dropdown btn-group" role="group">
            <button type="button" className="btn btn-sm btn-outline-secondary dropdown-toggle mx-2" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="bi bi-file-earmark-arrow-up"></i> {t('Common_ImportFile')}
            </button>
            <ul className="dropdown-menu gap-1 p-2 rounded-3 mx-0 shadow w-220px">
              <li>
                <button className="dropdown-item rounded-2" onClick={() => document.getElementById('importPdfFile').click()}>
                  <i class="bi bi-filetype-pdf"></i> PDF
                </button>
                <input type="file" accept="application/pdf"
                 id="importPdfFile" style={{"display": "none"}} onChange={handleFileUpload}/>
              </li>
              <li>
                <button className="dropdown-item rounded-2" onClick={() => document.getElementById('importWordFile').click()}>
                  <i class="bi bi-filetype-docx"></i> Word
                </button>
                <input type="file" accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                 id="importWordFile" style={{"display": "none"}} onChange={handleFileUpload}/>
              </li>
              <li>
                <button className="dropdown-item rounded-2" onClick={() => document.getElementById('importExcelFile').click()}>
                  <i class="bi bi-filetype-xlsx"></i> Excel
                </button>
                <input type="file" accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                 id="importExcelFile" style={{"display": "none"}} onChange={handleFileUpload}/>
              </li>
            </ul>
          </div>
          <div className="dropdown btn-group" role="group">
            <button type="button" className="btn btn-sm btn-outline-secondary dropdown-toggle mx-2" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="bi bi-file-earmark-arrow-down"></i> {t('Common_ExportFile')}
            </button>
            <ul className="dropdown-menu gap-1 p-2 rounded-3 mx-0 shadow w-220px">
              <li>
                <button className="dropdown-item rounded-2" onClick={(e) => handleFileDownload(e, 'PDF')}>
                  <i class="bi bi-filetype-pdf"></i> PDF
                </button>
              </li>
              <li>
                <button className="dropdown-item rounded-2" onClick={(e) => handleFileDownload(e, 'WORD')}>
                  <i class="bi bi-filetype-docx"></i> Word
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        id="freeform-text-box"
        contentEditable={true}
        onMouseUp={handleMouseUp}
        ref={docContentRef}
        className="p-4 border shadow-lg"
        style={{"white-space": "pre-wrap", "min-height": "600px"}}
        data-placeholder="Enter your text here..."
      >
      </div>
      {toolbarVisible && (
        <div
          style={{
            position: 'absolute',
            top: toolbarPosition.top,
            left: toolbarPosition.left,
            zIndex: 100,
          }}
        >
          <div class="btn-toolbar shadow p-1 bg-body-tertiary rounded" role="toolbar" aria-label="Toolbar with button groups">
            <div className="dropdown btn-group" role="group">
              <button type="button" className="btn btn-sm btn-outline-secondary mx-2" onClick={handleCommandButton}>
                <i class="bi bi-robot"></i> {t('Common_Command')}
              </button>
            </div>
            <FreeformToolbarButtons handleModify={handleModify}/>
          </div>
        </div>
      )}
      {commandVisible && (
        <div
          style={{
            position: 'absolute',
            top: toolbarPosition.top + 75,
            zIndex: 100,
          }}
          className="shadow p-3 bg-body-tertiary rounded"
        >
          <div class="input-group">
            <span class="input-group-text" id="basic-addon1"><i class="bi bi-command"></i></span>
            <input type="text" class="form-control" placeholder={t('Freeform_AICommandPlaceholder')} size="60"
             onChange={(event) => setCommand(event.target.value)} onKeyDown={handleCommandInputKeyDown} />
          </div>
        </div>
      )}
      {suggestedTextVisible && (
        <div
          style={{
            position: 'absolute',
            top: toolbarPosition.top + 75,
            left: toolbarPosition.left,
            zIndex: 100,
          }}
          className="shadow p-3 bg-body-tertiary rounded"
        >
          <div style={{"white-space": "pre-wrap", "max-height": "300px", "overflow-y": "auto"}}>
            {suggestedText}
          </div>
          <div class="mt-1" ref={suggestedTextAlertRef}></div>
          <div class="btn-group mt-1" role="group">
            <button type="button" class="btn btn-success" onClick={handleInsert}>{t('Freeform_InsertAfter')}</button>
            <button type="button" class="btn btn-warning" onClick={handleReplace}>{t('Freeform_Replace')}</button>
            <button type="button" class="btn btn-danger" onClick={handleDiscard}>{t('Freeform_Discard')}</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default FreeFormEditing;
