import React from "react";
import { useTranslation } from "react-i18next";
import {FormGroupText, FormLanguage, FormGenDoc} from "./generator-common"

function GenerateFormGeneralProductDesign() {
  const [userRequirements, setUserRequirements] = React.useState("");
  const [designGoals, setDesignGoals] = React.useState("");
  const [targetAudience, setTargetAudience] = React.useState("");
  const [competitorAnalysis, setCompetitorAnalysis] = React.useState("");
  const [branding, setBranding] = React.useState("");
  const [technicalRequirements, setTechnicalRequirements] = React.useState("");
  const [language, setLanguage] = React.useState("");
  const { t } = useTranslation();

  const requestDict = {
    userRequirements: userRequirements,
    designGoals: designGoals,
    targetAudience: targetAudience,
    competitorAnalysis: competitorAnalysis,
    branding: branding,
    technicalRequirements: technicalRequirements,
    language: language,
  };

  const setValueDict = {
    userRequirements: setUserRequirements,
    designGoals: setDesignGoals,
    targetAudience: setTargetAudience,
    competitorAnalysis: setCompetitorAnalysis,
    branding: setBranding,
    technicalRequirements: setTechnicalRequirements,
    language: setLanguage,
  };

  const formGroups = (
    <>
      <FormGroupText value={userRequirements} setValue={setUserRequirements} label={t('GeneralProductDesign_FieldUserRequirements')} tooltip={t('GeneralProductDesign_FieldUserRequirementsDesc')}/>
      <FormGroupText value={designGoals} setValue={setDesignGoals} label={t('GeneralProductDesign_FieldDesignGoals')} tooltip={t('GeneralProductDesign_FieldDesignGoalsDesc')}/>
      <FormGroupText value={targetAudience} setValue={setTargetAudience} label={t('GeneralProductDesign_FieldTargetAudience')} tooltip={t('GeneralProductDesign_FieldTargetAudienceDesc')}/>
      <FormGroupText value={competitorAnalysis} setValue={setCompetitorAnalysis} label={t('GeneralProductDesign_FieldCompetitorAnalysis')} tooltip={t('GeneralProductDesign_FieldCompetitorAnalysisDesc')}/>
      <FormGroupText value={branding} setValue={setBranding} label={t('GeneralProductDesign_FieldBranding')} tooltip={t('GeneralProductDesign_FieldBrandingDesc')}/>
      <FormGroupText value={technicalRequirements} setValue={setTechnicalRequirements} label={t('GeneralProductDesign_FieldTechnicalRequirements')} tooltip={t('GeneralProductDesign_FieldTechnicalRequirementsDesc')}/>
      <FormLanguage language={language} setLanguage={setLanguage} />
    </>
  );

  return <FormGenDoc 
    docType="GENERAL_PRODUCT_DESIGN"
    requestDict={requestDict}
    setValueDict={setValueDict}
    ajaxUrl="ajax_general_product_design"
    formTitle={t('GeneralProductDesign_FormTitle')}
    formGroups={formGroups} />;
}

export default GenerateFormGeneralProductDesign;
