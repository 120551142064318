import React from "react";
import { useTranslation } from "react-i18next";
import {FormGroupText, FormLanguage, FormGenDoc, FormGroupWorkExperience, FormGroupEducation, FormGroupSkills} from "./generator-common"

function GenerateFormPersonalStatement() {
  const [purpose, setPurpose] = React.useState("");
  const [achievements, setAchievements] = React.useState("");
  const [goals, setGoals] = React.useState("");
  const [workExperiences, setWorkExperiences] = React.useState([]);
  const [educations, setEducations] = React.useState([]);
  const [skills, setSkills] = React.useState([]);
  const [extracurricularActivities, setExtracurricularActivities] = React.useState("");
  const [language, setLanguage] = React.useState("");
  const { t } = useTranslation();

  const requestDict = {
    purpose: purpose,
    achievements: achievements,
    goals: goals,
    workExperiences: workExperiences,
    educations: educations,
    skills: skills,
    extracurricularActivities: extracurricularActivities,
    language: language,
  };

  const setValueDict = {
    purpose: setPurpose,
    achievements: setAchievements,
    goals: setGoals,
    workExperiences: setWorkExperiences,
    educations: setEducations,
    skills: setSkills,
    extracurricularActivities: setExtracurricularActivities,
    language: setLanguage,
  };

  const formGroups = (
    <>
      <FormGroupText value={purpose} setValue={setPurpose} label={t('PersonalStatement_FieldPurpose')} tooltip={t('PersonalStatement_FieldPurposeDesc')}/>
      <FormGroupText value={achievements} setValue={setAchievements} label={t('PersonalStatement_FieldAchievements')} tooltip={t('PersonalStatement_FieldAchievementsDesc')}/>
      <FormGroupText value={goals} setValue={setGoals} label={t('PersonalStatement_FieldGoals')} tooltip={t('PersonalStatement_FieldGoalsDesc')}/>
      <FormGroupWorkExperience workExperiences={workExperiences} setWorkExperiences={setWorkExperiences} />
      <FormGroupEducation educations={educations} setEducations={setEducations} />
      <FormGroupSkills skills={skills} setSkills={setSkills} />
      <FormGroupText value={extracurricularActivities} setValue={setExtracurricularActivities} label={t('PersonalStatement_FieldExtracurricularActivities')} tooltip={t('PersonalStatement_FieldExtracurricularActivitiesDesc')}/>
      <FormLanguage language={language} setLanguage={setLanguage} />
    </>
  );

  return <FormGenDoc 
    docType="PERSONAL_STATEMENT"
    requestDict={requestDict}
    setValueDict={setValueDict}
    ajaxUrl="ajax_personal_statement"
    formTitle={t('PersonalStatement_FormTitle')}
    formGroups={formGroups} />;
}

export default GenerateFormPersonalStatement;
