import React from "react";
import { useTranslation } from "react-i18next";
import {FormGroupText, FormLanguage, FormGenDoc} from "./generator-common"

function GenerateFormFitnessPlan() {
  const [fitnessGoals, setFitnessGoals] = React.useState("");
  const [fitnessLevel, setFitnessLevel] = React.useState("");
  const [fitnessEquipment, setFitnessEquipment] = React.useState("");
  const [workoutPreferences, setWorkoutPreferences] = React.useState("");
  const [language, setLanguage] = React.useState("");
  const { t } = useTranslation();

  const requestDict = {
    fitnessGoals: fitnessGoals,
    fitnessLevel: fitnessLevel,
    fitnessEquipment: fitnessEquipment,
    workoutPreferences: workoutPreferences,
    language: language,
  };

  const setValueDict = {
    fitnessGoals: setFitnessGoals,
    fitnessLevel: setFitnessLevel,
    fitnessEquipment: setFitnessEquipment,
    workoutPreferences: setWorkoutPreferences,
    language: setLanguage,
  };

  const formGroups = (
    <>
      <FormGroupText value={fitnessGoals} setValue={setFitnessGoals} label={t('FitnessPlan_FieldFitnessGoals')} tooltip={t('FitnessPlan_FieldFitnessGoalsDesc')}/>
      <FormGroupText value={fitnessLevel} setValue={setFitnessLevel} label={t('FitnessPlan_FieldFitnessLevel')} tooltip={t('FitnessPlan_FieldFitnessLevelDesc')}/>
      <FormGroupText value={fitnessEquipment} setValue={setFitnessEquipment} label={t('FitnessPlan_FieldFitnessEquipment')} tooltip={t('FitnessPlan_FieldFitnessEquipmentDesc')}/>
      <FormGroupText value={workoutPreferences} setValue={setWorkoutPreferences} label={t('FitnessPlan_FieldWorkoutPreferences')} tooltip={t('FitnessPlan_FieldWorkoutPreferencesDesc')}/>
      <FormLanguage language={language} setLanguage={setLanguage} />
    </>
  );

  return <FormGenDoc 
    docType="FITNESS_PLAN"
    requestDict={requestDict}
    setValueDict={setValueDict}
    ajaxUrl="ajax_fitness_plan"
    formTitle={t('FitnessPlan_FormTitle')}
    formGroups={formGroups} />;
}

export default GenerateFormFitnessPlan;
