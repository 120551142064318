import React from 'react';
import { useTranslation } from "react-i18next";

function Browse() {
  const { t } = useTranslation();

  return (
  <>
<div className="jumbotron text-center">
  <p>{t('Home_Choose')}:</p>
  <div className="container">
    <div className="row row-cols-1 row-cols-md-3 mb-3 text-center">
      <div className="col">
        <div className="card mb-4 rounded-3 shadow-sm border-primary">
          <div className="card-header py-3 text-bg-primary">
            <h4 className="my-0 fw-normal"><i className="bi bi-book"></i> {t('Home_SectionLiterature')}</h4>
          </div>
          <div className="card-body">
            <ul className="list-group list-group-flush">
              <li className="list-group-item"><a href="/gendoc/popular_science" className="btn btn-primary">{t('PopularScience_FormTitle')}</a></li>
              <li className="list-group-item"><a href="/gendoc/essay" className="btn btn-primary">{t('Essay_FormTitle')}</a></li>
              <li className="list-group-item"><a href="/gendoc/story" className="btn btn-primary">{t('Story_FormTitle')}</a></li>
              <li className="list-group-item"><a href="/gendoc/poetry" className="btn btn-primary">{t('Poetry_FormTitle')}</a></li>
              <li className="list-group-item"><a href="/gendoc/fable" className="btn btn-primary">{t('Fable_FormTitle')}</a></li>
              <li className="list-group-item"><a href="/gendoc/joke" className="btn btn-primary">{t('Joke_FormTitle')}</a></li>
              <li className="list-group-item"><a href="/gendoc/drama" className="btn btn-primary">{t('Drama_FormTitle')}</a></li>
              <li className="list-group-item"><a href="/gendoc/biography" className="btn btn-primary">{t('Biography_FormTitle')}</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col">
        <div className="card mb-4 rounded-3 shadow-sm border-success">
          <div className="card-header py-3 text-bg-success">
            <h4 className="my-0 fw-normal"><i className="bi bi-briefcase"></i> {t('Home_SectionProfessional')}</h4>
          </div>
          <div className="card-body">
            <ul className="list-group list-group-flush">
              <li className="list-group-item"><a href="/gendoc/resume" className="btn btn-success">{t('Resume_FormTitle')}</a></li>
              <li className="list-group-item"><a href="/gendoc/cover_letter" className="btn btn-success">{t('CoverLetter_FormTitle')}</a></li>
              <li className="list-group-item"><a href="/gendoc/personal_statement" className="btn btn-success">{t('PersonalStatement_FormTitle')}</a></li>
              <li className="list-group-item"><a href="/gendoc/political_education" className="btn btn-success">{t('PoliticalEducation_FormTitle')}</a></li>
              <li className="list-group-item"><a href="/gendoc/weekly_report" className="btn btn-success">{t('WeeklyReport_FormTitle')}</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col">
        <div className="card mb-4 rounded-3 shadow-sm border-danger">
          <div className="card-header py-3 text-bg-danger">
            <h4 className="my-0 fw-normal"><i className="bi bi-buildings"></i> {t('Home_SectionIndustry')}</h4>
          </div>
          <div className="card-body">
            <ul className="list-group list-group-flush">
              <li className="list-group-item"><a href="/gendoc/software_system_design" className="btn btn-danger">{t('SoftwareSystemDesign_FormTitle')}</a></li>
              <li className="list-group-item"><a href="/gendoc/software_product_design" className="btn btn-danger">{t('SoftwareProductDesign_FormTitle')}</a></li>
              <li className="list-group-item"><a href="/gendoc/general_product_design" className="btn btn-danger">{t('GeneralProductDesign_FormTitle')}</a></li>
              <li className="list-group-item"><a href="/gendoc/general_engineering_design" className="btn btn-danger">{t('GeneralEngineeringDesign_FormTitle')}</a></li>
              <li className="list-group-item"><a href="/gendoc/mission_statement" className="btn btn-danger">{t('MissionStatement_FormTitle')}</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col">
        <div className="card mb-4 rounded-3 shadow-sm border-info">
          <div className="card-header py-3 text-bg-info">
            <h4 className="my-0 fw-normal"><i className="bi bi-bank"></i> {t('Home_SectionBusiness')}</h4>
          </div>
          <div className="card-body">
            <ul className="list-group list-group-flush">
              <li className="list-group-item"><a href="/gendoc/advertising_proposal" className="btn btn-info">{t('AdvertisingProposal_FormTitle')}</a></li>
              <li className="list-group-item"><a href="/gendoc/business_plan" className="btn btn-info">{t('BusinessPlan_FormTitle')}</a></li>
              <li className="list-group-item"><a href="/gendoc/industry_research_report" className="btn btn-info">{t('IndustryResearchReport_FormTitle')}</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col">
        <div className="card mb-4 rounded-3 shadow-sm border-warning">
          <div className="card-header py-3 text-bg-warning">
            <h4 className="my-0 fw-normal"><i className="bi bi-laptop"></i> {t('Home_SectionAcademic')}</h4>
          </div>
          <div className="card-body">
            <ul className="list-group list-group-flush">
              <li className="list-group-item"><a href="/gendoc/research_paper" className="btn btn-warning">{t('ResearchPaper_FormTitle')}</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col">
        <div className="card mb-4 rounded-3 shadow-sm border-secondary">
          <div className="card-header py-3 text-bg-secondary">
            <h4 className="my-0 fw-normal"><i className="bi bi-person"></i> {t('Home_SectionPersonal')}</h4>
          </div>
          <div className="card-body">
            <ul className="list-group list-group-flush">
              <li className="list-group-item"><a href="/gendoc/love_letter" className="btn btn-secondary">{t('LoveLetter_FormTitle')}</a></li>
              <li className="list-group-item"><a href="/gendoc/study_plan" className="btn btn-secondary">{t('StudyPlan_FormTitle')}</a></li>
              <li className="list-group-item"><a href="/gendoc/fitness_plan" className="btn btn-secondary">{t('FitnessPlan_FormTitle')}</a></li>
              <li className="list-group-item"><a href="/gendoc/travel_plan" className="btn btn-secondary">{t('TravelPlan_FormTitle')}</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>    
  </div>
</div>
  </>
   );
}

export default Browse;