import React from "react";
import { useTranslation } from "react-i18next";
import {FormGroupText, FormLanguage, FormGenDoc, FormGroupWorkExperience, FormGroupEducation, FormGroupSkills, FormGroupAwards} from "./generator-common"

function GenerateFormResume() {
  const [name, setName] = React.useState("");
  const [profession, setProfession] = React.useState("");
  const [objective, setObjective] = React.useState("");
  const [workExperiences, setWorkExperiences] = React.useState([]);
  const [educations, setEducations] = React.useState([]);
  const [skills, setSkills] = React.useState([]);
  const [awards, setAwards] = React.useState([]);
  const [language, setLanguage] = React.useState("");
  const { t } = useTranslation();

  const requestDict = {
    name: name,
    profession: profession,
    objective: objective,
    workExperiences: workExperiences,
    educations: educations,
    skills: skills,
    awards: awards,
    language: language,
  };

  const setValueDict = {
    name: setName,
    profession: setProfession,
    objective: setObjective,
    workExperiences: setWorkExperiences,
    educations: setEducations,
    skills: setSkills,
    awards: setAwards,
    language: setLanguage,
  };

  const formGroups = (
    <>
      <FormGroupText value={name} setValue={setName} label={t('Resume_FieldName')} tooltip={t('Resume_FieldNameDesc')}/>
      <FormGroupText value={profession} setValue={setProfession} label={t('Resume_FieldProfession')} tooltip={t('Resume_FieldProfessionDesc')}/>
      <FormGroupText value={objective} setValue={setObjective} label={t('Resume_FieldObjective')} tooltip={t('Resume_FieldObjectiveDesc')}/>
      <FormGroupWorkExperience workExperiences={workExperiences} setWorkExperiences={setWorkExperiences} />
      <FormGroupEducation educations={educations} setEducations={setEducations} />
      <FormGroupSkills skills={skills} setSkills={setSkills} />
      <FormGroupAwards awards={awards} setAwards={setAwards} />
      <FormLanguage language={language} setLanguage={setLanguage} />
    </>
  );

  return <FormGenDoc 
    docType="RESUME"
    requestDict={requestDict}
    setValueDict={setValueDict}
    ajaxUrl="ajax_resume"
    formTitle={t('Resume_FormTitle')}
    formGroups={formGroups} />;
}

export default GenerateFormResume;
