import React from "react";
import { useTranslation } from "react-i18next";
import {FormGroupText, FormLanguage, FormGenDoc} from "./generator-common"

function GenerateFormTravelPlan() {
  const [destination, setDestination] = React.useState("");
  const [travelDates, setTravelDates] = React.useState("");
  const [interestsAndActivities, setInterestsAndActivities] = React.useState("");
  const [accommodation, setAccommodation] = React.useState("");
  const [transportation, setTransportation] = React.useState("");
  const [budget, setBudget] = React.useState("");
  const [language, setLanguage] = React.useState("");
  const { t } = useTranslation();

  const requestDict = {
    destination: destination,
    travelDates: travelDates,
    interestsAndActivities: interestsAndActivities,
    accommodation: accommodation,
    transportation: transportation,
    budget: budget,
    language: language,
  };

  const setValueDict = {
    destination: setDestination,
    travelDates: setTravelDates,
    interestsAndActivities: setInterestsAndActivities,
    accommodation: setAccommodation,
    transportation: setTransportation,
    budget: setBudget,
    language: setLanguage,
  };

  const formGroups = (
    <>
      <FormGroupText value={destination} setValue={setDestination} label={t('TravelPlan_FieldDestination')} tooltip={t('TravelPlan_FieldDestinationDesc')}/>
      <FormGroupText value={travelDates} setValue={setTravelDates} label={t('TravelPlan_FieldTravelDates')} tooltip={t('TravelPlan_FieldTravelDatesDesc')}/>
      <FormGroupText value={interestsAndActivities} setValue={setInterestsAndActivities} label={t('TravelPlan_FieldInterestsAndActivities')} tooltip={t('TravelPlan_FieldInterestsAndActivitiesDesc')}/>
      <FormGroupText value={accommodation} setValue={setAccommodation} label={t('TravelPlan_FieldAccommodation')} tooltip={t('TravelPlan_FieldAccommodationDesc')}/>
      <FormGroupText value={transportation} setValue={setTransportation} label={t('TravelPlan_FieldTransportation')} tooltip={t('TravelPlan_FieldTransportationDesc')}/>
      <FormGroupText value={budget} setValue={setBudget} label={t('TravelPlan_FieldBudget')} tooltip={t('TravelPlan_FieldBudgetDesc')}/>
      <FormLanguage language={language} setLanguage={setLanguage} />
    </>
  );

  return <FormGenDoc 
    docType="TRAVEL_PLAN"
    requestDict={requestDict}
    setValueDict={setValueDict}
    ajaxUrl="ajax_travel_plan"
    formTitle={t('TravelPlan_FormTitle')}
    formGroups={formGroups} />;
}

export default GenerateFormTravelPlan;
