import React from "react";
import { useTranslation } from "react-i18next";
import {FormGroupText, FormLanguage, FormGenDoc} from "./generator-common"

function GenerateFormIndustryResearchReport() {
  const [industrySector, setIndustrySector] = React.useState("");
  const [marketSize, setMarketSize] = React.useState("");
  const [marketTrends, setMarketTrends] = React.useState("");
  const [investmentOpportunities, setInvestmentOpportunities] = React.useState("");
  const [competitiveLandscape, setCompetitiveLandscape] = React.useState("");
  const [language, setLanguage] = React.useState("");
  const { t } = useTranslation();

  const requestDict = {
    industrySector: industrySector,
    marketSize: marketSize,
    marketTrends: marketTrends,
    investmentOpportunities: investmentOpportunities,
    competitiveLandscape: competitiveLandscape,
    language: language,
  };

  const setValueDict = {
    industrySector: setIndustrySector,
    marketSize: setMarketSize,
    marketTrends: setMarketTrends,
    investmentOpportunities: setInvestmentOpportunities,
    competitiveLandscape: setCompetitiveLandscape,
    language: setLanguage,
  };

  const formGroups = (
    <>
      <FormGroupText value={industrySector} setValue={setIndustrySector} label={t('IndustryResearchReport_FieldIndustrySector')} tooltip={t('IndustryResearchReport_FieldIndustrySectorDesc')}/>
      <FormGroupText value={marketSize} setValue={setMarketSize} label={t('IndustryResearchReport_FieldMarketSize')} tooltip={t('IndustryResearchReport_FieldMarketSizeDesc')}/>
      <FormGroupText value={marketTrends} setValue={setMarketTrends} label={t('IndustryResearchReport_FieldMarketTrends')} tooltip={t('IndustryResearchReport_FieldMarketTrendsDesc')}/>
      <FormGroupText value={investmentOpportunities} setValue={setInvestmentOpportunities} label={t('IndustryResearchReport_FieldInvestmentOpportunities')} tooltip={t('IndustryResearchReport_FieldInvestmentOpportunitiesDesc')}/>
      <FormGroupText value={competitiveLandscape} setValue={setCompetitiveLandscape} label={t('IndustryResearchReport_FieldCompetitiveLandscape')} tooltip={t('IndustryResearchReport_FieldCompetitiveLandscapeDesc')}/>
      <FormLanguage language={language} setLanguage={setLanguage} />
    </>
  );

  return <FormGenDoc 
    docType="INDUSTRY_RESEARCH_REPORT"
    requestDict={requestDict}
    setValueDict={setValueDict}
    ajaxUrl="ajax_industry_research_report"
    formTitle={t('IndustryResearchReport_FormTitle')}
    formGroups={formGroups} />;
}

export default GenerateFormIndustryResearchReport;
