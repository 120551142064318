import React from "react";
import { useTranslation } from "react-i18next";
import {FormGroupText, FormLanguage, FormGenDoc} from "./generator-common"

function GenerateFormDrama() {
  const [setting, setSetting] = React.useState("");
  const [characters, setCharacters] = React.useState("");
  const [conflict, setConflict] = React.useState("");
  const [theme, setTheme] = React.useState("");
  const [language, setLanguage] = React.useState("");
  const { t } = useTranslation();

  const requestDict = {
    setting: setting,
    characters: characters,
    conflict: conflict,
    theme: theme,
    language: language,
  };

  const setValueDict = {
    setting: setSetting,
    characters: setCharacters,
    conflict: setConflict,
    theme: setTheme,
    language: setLanguage,
  };

  const formGroups = (
    <>
      <FormGroupText value={setting} setValue={setSetting} label={t('Drama_FieldSetting')} tooltip={t('Drama_FieldSettingDesc')}/>
      <FormGroupText value={characters} setValue={setCharacters} label={t('Drama_FieldCharacters')} tooltip={t('Drama_FieldCharactersDesc')}/>
      <FormGroupText value={conflict} setValue={setConflict} label={t('Drama_FieldConflict')} tooltip={t('Drama_FieldConflictDesc')}/>
      <FormGroupText value={theme} setValue={setTheme} label={t('Drama_FieldTheme')} tooltip={t('Drama_FieldThemeDesc')}/>
      <FormLanguage language={language} setLanguage={setLanguage} />
    </>
  );

  return <FormGenDoc 
    docType="DRAMA"
    requestDict={requestDict}
    setValueDict={setValueDict}
    ajaxUrl="ajax_drama"
    formTitle={t('Drama_FormTitle')}
    formGroups={formGroups} />;
}

export default GenerateFormDrama;
