import React from "react";
import { useTranslation } from "react-i18next";
import {FormGroupText, FormLanguage, FormGenDoc} from "./generator-common"

function GenerateFormSoftwareSystemDesign() {
  const [systemRequirements, setSystemRequirements] = React.useState("");
  const [systemArchitecture, setSystemArchitecture] = React.useState("");
  const [designPatterns, setDesignPatterns] = React.useState("");
  const [dataModel, setDataModel] = React.useState("");
  const [userInterface, setUserInterface] = React.useState("");
  const [architectureDesign, setArchitectureDesign] = React.useState("");
  const [databaseDesign, setDatabaseDesign] = React.useState("");
  const [interfaceDesign, setInterfaceDesign] = React.useState("");
  const [securityDesign, setSecurityDesign] = React.useState("");
  const [language, setLanguage] = React.useState("");
  const { t } = useTranslation();

  const requestDict = {
    systemRequirements: systemRequirements,
    systemArchitecture: systemArchitecture,
    designPatterns: designPatterns,
    dataModel: dataModel,
    userInterface: userInterface,
    architectureDesign: architectureDesign,
    databaseDesign: databaseDesign,
    interfaceDesign: interfaceDesign,
    securityDesign: securityDesign,
    language: language,
  };

  const setValueDict = {
    systemRequirements: setSystemRequirements,
    systemArchitecture: setSystemArchitecture,
    designPatterns: setDesignPatterns,
    dataModel: setDataModel,
    userInterface: setUserInterface,
    architectureDesign: setArchitectureDesign,
    databaseDesign: setDatabaseDesign,
    interfaceDesign: setInterfaceDesign,
    securityDesign: setSecurityDesign,
    language: setLanguage,
  };

  const formGroups = (
    <>
      <FormGroupText value={systemRequirements} setValue={setSystemRequirements} label={t('SoftwareSystemDesign_FieldSystemRequirements')} tooltip={t('SoftwareSystemDesign_FieldSystemRequirementsDesc')}/>
      <FormGroupText value={systemArchitecture} setValue={setSystemArchitecture} label={t('SoftwareSystemDesign_FieldSystemArchitecture')} tooltip={t('SoftwareSystemDesign_FieldSystemArchitectureDesc')}/>
      <FormGroupText value={designPatterns} setValue={setDesignPatterns} label={t('SoftwareSystemDesign_FieldDesignPatterns')} tooltip={t('SoftwareSystemDesign_FieldDesignPatternsDesc')}/>
      <FormGroupText value={dataModel} setValue={setDataModel} label={t('SoftwareSystemDesign_FieldDataModel')} tooltip={t('SoftwareSystemDesign_FieldDataModelDesc')}/>
      <FormGroupText value={userInterface} setValue={setUserInterface} label={t('SoftwareSystemDesign_FieldUserInterface')} tooltip={t('SoftwareSystemDesign_FieldUserInterfaceDesc')}/>
      <FormGroupText value={architectureDesign} setValue={setArchitectureDesign} label={t('SoftwareSystemDesign_FieldArchitectureDesign')} tooltip={t('SoftwareSystemDesign_FieldArchitectureDesignDesc')}/>
      <FormGroupText value={databaseDesign} setValue={setDatabaseDesign} label={t('SoftwareSystemDesign_FieldDatabaseDesign')} tooltip={t('SoftwareSystemDesign_FieldDatabaseDesignDesc')}/>
      <FormGroupText value={interfaceDesign} setValue={setInterfaceDesign} label={t('SoftwareSystemDesign_FieldInterfaceDesign')} tooltip={t('SoftwareSystemDesign_FieldInterfaceDesignDesc')}/>
      <FormGroupText value={securityDesign} setValue={setSecurityDesign} label={t('SoftwareSystemDesign_FieldSecurityDesign')} tooltip={t('SoftwareSystemDesign_FieldSecurityDesignDesc')}/>
      <FormLanguage language={language} setLanguage={setLanguage} />
    </>
  );

  return <FormGenDoc 
    docType="SOFTWARE_SYSTEM_DESIGN"
    requestDict={requestDict}
    setValueDict={setValueDict}
    ajaxUrl="ajax_software_system_design"
    formTitle={t('SoftwareSystemDesign_FormTitle')}
    formGroups={formGroups} />;
}

export default GenerateFormSoftwareSystemDesign;
