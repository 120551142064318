import React from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import * as ReactDOM from 'react-dom';
import { makeAjaxRequest } from "../common.js";


function addAlert(message, type, alertPlaceholderRef) {
  const wrapper = (
    <div className={`alert alert-${type} alert-dismissible`} role="alert">
       <div>{message}</div>
       <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>
  );

  ReactDOM.createRoot(alertPlaceholderRef.current).render(wrapper);
}

function FormGenDoc({docType, requestDict, setValueDict, ajaxUrl, formTitle, formGroups}) {
  const { t } = useTranslation();

  const docContentRef = React.useRef(null);
  const [externalDocId, setExternalDocId] = React.useState("");
  const [disableModify, setDisableModify] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [imageSlides, setImageSlides] = React.useState([]);
  const [activeImageSlideIndex, setActiveImageSlideIndex] = React.useState(0);

  React.useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const externalDocId = queryParameters.get("external_doc_id");
    if (externalDocId) {
      // Load form with external_doc_id
      const getDocRequestDict = {
        "externalDocId": externalDocId,
      };
      const callback = (result) => {
        docContentRef.current.innerHTML = result["resultText"];
        for (let key in result["userRequest"]) {
          let val = result["userRequest"][key];
          if (key in setValueDict) {
            setValueDict[key](val);
          }
        }

        setExternalDocId(result["externalDocId"]);
        setDisableModify(false);
      }
      makeAjaxRequest("/gendoc/ajax_get_doc", getDocRequestDict, callback, setErrorMessage, setIsLoading);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const callback = (result) => {
      docContentRef.current.innerHTML = result["resultText"];
      setExternalDocId(result["externalDocId"]);
      setDisableModify(false);
    }
    makeAjaxRequest("/gendoc/" + ajaxUrl, requestDict, callback, setErrorMessage, setIsLoading);
  };

  const handleModify = (action) => {
    const modifyRequestDict = {
      'externalDocId': externalDocId,
      'docType': docType,
      'action': action,
      'text': docContentRef.current.innerHTML,
      'language': requestDict['language'],
    };
    const callback = (result) => {
      docContentRef.current.innerHTML = result["resultText"];
    }
    makeAjaxRequest("/gendoc/ajax_modify", modifyRequestDict, callback, setErrorMessage, setIsLoading);
  };

  const handleGenImage = (imageType) => {
    const genImageRequestDict = {
      'docType': docType,
      'imageType': imageType,
      'text': docContentRef.current.innerHTML,
      'externalDocId': externalDocId,
    };
    const callback = (result) => {
      const newSlide = {
        id: imageSlides.length,
        imgSrc: result["imageURL"],
      };

      setImageSlides([...imageSlides, newSlide]);
      // Make the newly added slide active
      setActiveImageSlideIndex(imageSlides.length);
    }
    makeAjaxRequest("/gendoc/ajax_gen_image", genImageRequestDict, callback, setErrorMessage, setIsLoading);
  };

  return (
    <div className="container">
      <div>
        {formTitle && <h1>{formTitle}</h1>}
        <form onSubmit={handleSubmit}>
          {formGroups}
          <br/>
          <button type="submit" className="btn btn-primary">
            {t('Common_ButtonGenerate')}
          </button>
        </form>
        {errorMessage &&
          <div className="alert alert-danger" role="alert">{errorMessage}</div>
        }
      </div>
      <br/>
      {isLoading && (
        <div id="loading-root" className="spinner-border text-primary" role="status"></div>
      )}
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h4> </h4>
        <div className="btn-toolbar mb-2 mb-md-0">
          <div className="btn-group me-2">
            <button type="button" className="btn btn-sm btn-outline-secondary" disabled={disableModify}
            onClick={() => {navigator.clipboard.writeText(docContentRef.current.innerHTML)}}>
              <i className="bi bi-clipboard"></i> {t('Common_CopyToClipboard')}
            </button>
          </div>
          <div className="dropdown">
            <button type="button" className="btn btn-sm btn-outline-secondary dropdown-toggle me-2" data-bs-toggle="dropdown" aria-expanded="false" disabled={disableModify}>
              <i className="bi bi-file-earmark-word"></i> {t('Common_Words')}
            </button>
            <ul className="dropdown-menu gap-1 p-2 rounded-3 mx-0 shadow w-220px">
              <li><button className="dropdown-item rounded-2" onClick={() => handleModify("MORE_WORDS")}>
                <i className="bi bi-file-earmark-plus"></i> {t('Common_MoreWords')}
              </button></li>
              <li><button className="dropdown-item rounded-2" onClick={() => handleModify("LESS_WORDS")}>
                <i className="bi bi-file-earmark-minus"></i> {t('Common_LessWords')}
              </button></li>
            </ul>
          </div>
          <div className="dropdown">
            <button type="button" className="btn btn-sm btn-outline-secondary dropdown-toggle me-2" data-bs-toggle="dropdown" aria-expanded="false" disabled={disableModify}>
              <i className="bi bi-chat-left-dots"></i> {t('Common_Tone')}
            </button>
            <ul className="dropdown-menu gap-1 p-2 rounded-3 mx-0 shadow w-220px">
              <li><button className="dropdown-item rounded-2" onClick={() => handleModify("TONE_CONFIDENT")}>
                <i className="bi bi-chat-left"></i> {t('Common_ToneConfident')}
              </button></li>
              <li><button className="dropdown-item rounded-2" onClick={() => handleModify("TONE_EMOTIONAL")}>
                <i className="bi bi-chat-left"></i> {t('Common_ToneEmotional')}
              </button></li>
              <li><button className="dropdown-item rounded-2" onClick={() => handleModify("TONE_EMPATHETIC")}>
                <i className="bi bi-chat-left"></i> {t('Common_ToneEmpathetic')}
              </button></li>
              <li><button className="dropdown-item rounded-2" onClick={() => handleModify("TONE_FORMAL")}>
                <i className="bi bi-chat-left"></i> {t('Common_ToneFormal')}
              </button></li>
              <li><button className="dropdown-item rounded-2" onClick={() => handleModify("TONE_FRIENDLY")}>
                <i className="bi bi-chat-left"></i> {t('Common_ToneFriendly')}
              </button></li>
              <li><button className="dropdown-item rounded-2" onClick={() => handleModify("TONE_HUMOROUS")}>
                <i className="bi bi-chat-left"></i> {t('Common_ToneHumorous')}
              </button></li>
              <li><button className="dropdown-item rounded-2" onClick={() => handleModify("TONE_OPTIMISTIC")}>
                <i className="bi bi-chat-left"></i> {t('Common_ToneOptimistic')}
              </button></li>
              <li><button className="dropdown-item rounded-2" onClick={() => handleModify("TONE_PESSIMISTIC")}>
                <i className="bi bi-chat-left"></i> {t('Common_TonePessimistic')}
              </button></li>
              <li><button className="dropdown-item rounded-2" onClick={() => handleModify("TONE_PROFESSIONAL")}>
                <i className="bi bi-chat-left"></i> {t('Common_ToneProfessional')}
              </button></li>
              <li><button className="dropdown-item rounded-2" onClick={() => handleModify("TONE_SARCASTIC")}>
                <i className="bi bi-chat-left"></i> {t('Common_ToneSarcastic')}
              </button></li>
            </ul>
          </div>
          <div className="dropdown">
            <button type="button" className="btn btn-sm btn-outline-secondary dropdown-toggle me-2" data-bs-toggle="dropdown" aria-expanded="false" disabled={disableModify}>
              <i className="bi bi-palette"></i> {t('Common_GenImage')}
            </button>
            <ul className="dropdown-menu gap-1 p-2 rounded-3 mx-0 shadow w-220px">
              <li><button className="dropdown-item rounded-2" onClick={() => handleGenImage("ANIME_WORLD")}>
                <i className="bi bi-brush"></i> {t('Common_GenImageAnimeWorld')}
              </button></li>
              <li><button className="dropdown-item rounded-2" onClick={() => handleGenImage("FANTASY_WORLD")}>
                <i className="bi bi-brush"></i> {t('Common_GenImageFantasyWorld')}
              </button></li>
              <li><button className="dropdown-item rounded-2" onClick={() => handleGenImage("OLD_STYLE")}>
                <i className="bi bi-brush"></i> {t('Common_GenImageOldStyle')}
              </button></li>
              <li><button className="dropdown-item rounded-2" onClick={() => handleGenImage("RENAISSANCE_PAINTING")}>
                <i className="bi bi-brush"></i> {t('Common_GenImageRenaissancePainting')}
              </button></li>
              <li><button className="dropdown-item rounded-2" onClick={() => handleGenImage("3D_OBJECTS")}>
                <i className="bi bi-brush"></i> {t('Common_GenImage3DObjects')}
              </button></li>
              <li><button className="dropdown-item rounded-2" onClick={() => handleGenImage("WATERCOLOR_PAINTING")}>
                <i className="bi bi-brush"></i> {t('Common_GenImageWatercolorPainting')}
              </button></li>
            </ul> 
          </div>
        </div>
      </div>
      <GenImageCarousol slides={imageSlides} activeImageSlideIndex={activeImageSlideIndex} setActiveImageSlideIndex={setActiveImageSlideIndex} />
      <div id="result-root" style={{whiteSpace: "pre-wrap"}} className="p-4 border shadow-lg" contentEditable="true" ref={docContentRef}></div>
      <br/>
    </div>
  );
}

function GenImageCarousol({slides, activeImageSlideIndex, setActiveImageSlideIndex}) {
  return (
    <div id="gen-image-carousel" className="carousel slide mx-auto" data-ride="carousel" style={{"maxWidth": "512px"}}>
      <div className="carousel-indicators">
        {slides.map((slide, index) => (
          <button
            data-bs-target="#gen-image-carousel"
            data-bs-slide-to={index}
            className={index === activeImageSlideIndex ? "active" : ""}
            onClick={() => setActiveImageSlideIndex(index)}
          ></button>
        ))}
      </div>

      <div className="carousel-inner">
        {slides.map((slide, index) => (
          <div
            key={slide.id}
            className={`carousel-item ${index === activeImageSlideIndex ? "active" : ""}`}
          >
            <img src={slide.imgSrc} className="img-fluid" />
          </div>
        ))}
      </div>

      <button
        className="carousel-control-prev"
        href="#gen-image-carousel"
        type="button"
        data-bs-slide="prev"
        onClick={() => setActiveImageSlideIndex(activeImageSlideIndex === 0 ? slides.length - 1 : activeImageSlideIndex - 1)}
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        href="#gen-image-carousel"
        type="button"
        data-bs-slide="next"
        onClick={() => setActiveImageSlideIndex(activeImageSlideIndex === slides.length - 1 ? 0 : activeImageSlideIndex + 1)}
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

function getRandomFormId() {
  return "fieldId" + Math.floor(Math.random() * 2147483647).toString();
}

function FormGroupText({value, setValue, label, tooltip}) {
  const fieldId = getRandomFormId();
  return (
    <div className="form-group row mb-2" data-toggle="tooltip" data-placement="bottom"
     title={tooltip}>
      <label htmlFor={fieldId} className="col-sm-2 col-form-label fw-bold">{label}:</label>
      <div className="col-sm-10">
      <input
        type="text"
        className="form-control"
        id={fieldId}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      </div>
    </div>
  );
}

function FormLanguage({language, setLanguage}) {
  const { t } = useTranslation();

  if (language === "") {
    if (i18n.language === "cn" || i18n.language === "zh")
      setLanguage("zh-CN");
    else if (i18n.language === "en-US")
      setLanguage("en");
    else if (i18n.language)
      setLanguage(i18n.language);
    else
      setLanguage("en");
  }
  return (
    <div className="form-group row mb-2" data-toggle="tooltip" data-placement="bottom"
     title={t('Common_FieldLanguageDesc')}>
      <label htmlFor="languageId" className="col-sm-2 col-form-label fw-bold">{t('Common_FieldLanguage')}:</label>
      <div className="col-sm-10">
      <select className="form-select" id="languageId" value={language} onChange={(e) => setLanguage(e.target.value)}>
        <option value="af">Afrikaans</option>
        <option value="sq">Albanian</option>
        <option value="am">Amharic</option>
        <option value="ar">Arabic</option>
        <option value="hy">Armenian</option>
        <option value="az">Azerbaijani</option>
        <option value="eu">Basque</option>
        <option value="be">Belarusian</option>
        <option value="bn">Bengali</option>
        <option value="bs">Bosnian</option>
        <option value="bg">Bulgarian</option>
        <option value="ca">Catalan</option>
        <option value="ceb">Cebuano</option>
        <option value="ny">Chichewa</option>
        <option value="zh-CN">Chinese (Simplified)</option>
        <option value="zh-TW">Chinese (Traditional)</option>
        <option value="co">Corsican</option>
        <option value="hr">Croatian</option>
        <option value="cs">Czech</option>
        <option value="da">Danish</option>
        <option value="nl">Dutch</option>
        <option value="en">English</option>
        <option value="eo">Esperanto</option>
        <option value="et">Estonian</option>
        <option value="tl">Filipino</option>
        <option value="fi">Finnish</option>
        <option value="fr">French</option>
        <option value="fy">Frisian</option>
        <option value="gl">Galician</option>
        <option value="ka">Georgian</option>
        <option value="de">German</option>
        <option value="el">Greek</option>
        <option value="gu">Gujarati</option>
        <option value="ht">Haitian Creole</option>
        <option value="ha">Hausa</option>
        <option value="haw">Hawaiian</option>
        <option value="iw">Hebrew</option>
        <option value="hi">Hindi</option>
        <option value="hmn">Hmong</option>
        <option value="hu">Hungarian</option>
        <option value="is">Icelandic</option>
        <option value="ig">Igbo</option>
        <option value="id">Indonesian</option>
        <option value="ga">Irish</option>
        <option value="it">Italian</option>
        <option value="ja">Japanese</option>
        <option value="jw">Javanese</option>
        <option value="kn">Kannada</option>
        <option value="kk">Kazakh</option>
        <option value="km">Khmer</option>
        <option value="rw">Kinyarwanda</option>
        <option value="ko">Korean</option>
        <option value="ku">Kurdish (Kurmanji)</option>
        <option value="ky">Kyrgyz</option>
        <option value="lo">Lao</option>
        <option value="la">Latin</option>
        <option value="lv">Latvian</option>
        <option value="lt">Lithuanian</option>
        <option value="lb">Luxembourgish</option>
        <option value="mk">Macedonian</option>
        <option value="mg">Malagasy</option>
        <option value="ms">Malay</option>
        <option value="ml">Malayalam</option>
        <option value="mt">Maltese</option>
        <option value="mi">Maori</option>
        <option value="mr">Marathi</option>
        <option value="mn">Mongolian</option>
        <option value="my">Myanmar (Burmese)</option>
        <option value="ne">Nepali</option>
        <option value="no">Norwegian</option>
        <option value="or">Odia (Oriya)</option>
        <option value="ps">Pashto</option>
        <option value="fa">Persian</option>
        <option value="pl">Polish</option>
        <option value="pt">Portuguese</option>
        <option value="pa">Punjabi</option>
        <option value="ro">Romanian</option>
        <option value="ru">Russian</option>
        <option value="sm">Samoan</option>
        <option value="gd">Scots Gaelic</option>
        <option value="sr">Serbian</option>
        <option value="st">Sesotho</option>
        <option value="sn">Shona</option>
        <option value="sd">Sindhi</option>
        <option value="si">Sinhala (Sinhalese)</option>
        <option value="sk">Slovak</option>
        <option value="sl">Slovenian</option>
        <option value="so">Somali</option>
        <option value="es">Spanish</option>
        <option value="su">Sundanese</option>
        <option value="sw">Swahili</option>
        <option value="sv">Swedish</option>
        <option value="tg">Tajik</option>
        <option value="ta">Tamil</option>
        <option value="tt">Tatar</option>
        <option value="te">Telugu</option>
        <option value="th">Thai</option>
        <option value="tr">Turkish</option>
        <option value="tk">Turkmen</option>
        <option value="uk">Ukrainian</option>
        <option value="ur">Urdu</option>
        <option value="ug">Uyghur</option>
        <option value="uz">Uzbek</option>
        <option value="vi">Vietnamese</option>
        <option value="cy">Welsh</option>
        <option value="xh">Xhosa</option>
        <option value="yi">Yiddish</option>
        <option value="yo">Yoruba</option>
        <option value="zu">Zulu</option>
      </select>
      </div>
    </div>
  );
}

function FormGroupWorkExperience({workExperiences, setWorkExperiences}) {
  const { t } = useTranslation();
  const [counter, setCounter] = React.useState(1);

  const addExperience = () => {
    setWorkExperiences([
      ...workExperiences,
      {
        companyName: "",
        jobTitle: "",
        startDate: "",
        endDate: "",
        jobDescription: "",
      },
    ]);
    setCounter(counter + 1);
  };

  const removeExperience = (index) => {
    setWorkExperiences(
      workExperiences.filter((experience, i) => {
        return i !== index;
      })
    );
  };

  const handleChange = (index, event) => {
    let newExperiences = [...workExperiences];
    newExperiences[index][event.target.name] = event.target.value;
    setWorkExperiences(newExperiences);
  };

  return (
    <div className="form-group row mb-2" data-toggle="tooltip" data-placement="bottom" title={t('Common_FieldWorkExperienceDesc')}>
      <label htmlFor="workExperienceField" className="col-sm-2 col-form-label fw-bold">{t('Common_FieldWorkExperience')}:</label>
      <div className="col-sm-10">
      {workExperiences.map((experience, index) => (
        <div className="row mb-1" key={index}>
          <div className="form-group col-sm-6">
            <label htmlFor={`companyName${index}`}>{t('Common_FieldCompanyName')}:</label>
            <input
              type="text"
              className="form-control"
              id={`companyName${index}`}
              name="companyName"
              value={experience.companyName}
              onChange={(event) => handleChange(index, event)}
            />
          </div>
          <div className="form-group col-sm-6">
            <label htmlFor={`jobTitle${index}`}>{t('Common_FieldJobTitle')}:</label>
            <input
              type="text"
              className="form-control"
              id={`jobTitle${index}`}
              name="jobTitle"
              value={experience.jobTitle}
              onChange={(event) => handleChange(index, event)}
            />
          </div>
          <div className="form-group col-sm-3">
            <label htmlFor={`startDate${index}`}>{t('Common_FieldStartDate')}:</label>
            <input
              type="date"
              className="form-control"
              id={`startDate${index}`}
              name="startDate"
              value={experience.startDate}
              onChange={(event) => handleChange(index, event)}
            />
          </div>
          <div className="form-group col-sm-3">
            <label htmlFor={`endDate${index}`}>{t('Common_FieldEndDate')}:</label>
            <input
              type="date"
              className="form-control"
              id={`endDate${index}`}
              name="endDate"
              value={experience.endDate}
              onChange={(event) => handleChange(index, event)}
            />
          </div>
          <div className="form-group col-sm-12">
            <label htmlFor={`jobDescription${index}`}>{t('Common_FieldJobDescription')}:</label>
            <input
              type="text"
              className="form-control"
              id={`jobDescription{index}`}
              name="jobDescription"
              value={experience.jobDescription}
              onChange={(event) => handleChange(index, event)}
            />
          </div>
          <div className="form-group col-sm-4">
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => removeExperience(index)}
            >
              {t('Common_ButtonRemove')}
            </button>
          </div>
        </div>
      ))}
      <div className="form-group col-sm-4">
        <button
          type="button"
          className="btn btn-success"
          onClick={addExperience}
        >
          {t('Common_ButtonAddCompany')}
        </button>
      </div>
      </div>
    </div>
  );
};

function FormGroupEducation({educations, setEducations}) {
  const { t } = useTranslation();
  const [counter, setCounter] = React.useState(1);

  const addEducation = () => {
    setEducations([
      ...educations,
      {
        schoolName: "",
        degree: "",
        fieldOfStudy: "",
        startYear: "",
        endYear: "",
      },
    ]);
    setCounter(counter + 1);
  };

  const removeEducation = (index) => {
    setEducations(
      educations.filter((education, i) => {
        return i !== index;
      })
    );
  };

  const handleChange = (index, event) => {
    let newEducations = [...educations];
    newEducations[index][event.target.name] = event.target.value;
    setEducations(newEducations);
  };

  return (
    <div className="form-group row mb-2" data-toggle="tooltip" data-placement="bottom" title={t('Common_FieldEducationDesc')}>
      <label htmlFor="educationField" className="col-sm-2 col-form-label fw-bold">{t('Common_FieldEducation')}:</label>
      <div className="col-sm-10">
      {educations.map((education, index) => (
        <div className="row mb-1" key={index}>
          <div className="form-group col-sm-8">
            <label htmlFor={`schoolName${index}`}>{t('Common_FieldSchoolName')}:</label>
            <input
              type="text"
              className="form-control"
              id={`schoolName${index}`}
              name="schoolName"
              value={education.schoolName}
              onChange={(event) => handleChange(index, event)}
            />
          </div>
          <div className="form-group col-sm-2">
            <label htmlFor={`startYear${index}`}>{t('Common_FieldStartYear')}:</label>
            <input
              type="text"
              className="form-control"
              id={`startYear${index}`}
              name="startYear"
              value={education.startYear}
              onChange={(event) => handleChange(index, event)}
            />
          </div>
          <div className="form-group col-sm-2">
            <label htmlFor={`endYear${index}`}>{t('Common_FieldEndYear')}:</label>
            <input
              type="text"
              className="form-control"
              id={`endYear${index}`}
              name="endYear"
              value={education.endYear}
              onChange={(event) => handleChange(index, event)}
            />
          </div>
          <div className="form-group col-sm-3">
            <label htmlFor={`degree${index}`}>{t('Common_FieldDegree')}:</label>
            <input
              type="text"
              className="form-control"
              id={`degree${index}`}
              name="degree"
              value={education.degree}
              onChange={(event) => handleChange(index, event)}
            />
          </div>
          <div className="form-group col-sm-9">
            <label htmlFor={`fieldOfStudy${index}`}>{t('Common_FieldFieldOfStudy')}:</label>
            <input
              type="text"
              className="form-control"
              id={`fieldOfStudy${index}`}
              name="fieldOfStudy"
              value={education.fieldOfStudy}
              onChange={(event) => handleChange(index, event)}
            />
          </div>
          <div className="form-group col-sm-4">
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => removeEducation(index)}
            >
              {t('Common_ButtonRemove')}
            </button>
          </div>
        </div>
      ))}
      <div className="form-group col-sm-4">
        <button
          type="button"
          className="btn btn-success"
          onClick={addEducation}
        >
          {t('Common_ButtonAddSchool')}
        </button>
      </div>
      </div>
    </div>
  );
};

function FormGroupSkills({skills, setSkills}) {
  const { t } = useTranslation();
  const [counter, setCounter] = React.useState(1);

  const addSkill = () => {
    setSkills([
      ...skills,
      "",
    ]);
    setCounter(counter + 1);
  };

  const handleRemoveSkill = (index) => {
    setSkills(skills.filter((skill, i) => i !== index));
  };

  const handleChange = (index, event) => {
    let newSkills = [...skills];
    newSkills[index] = event.target.value;
    setSkills(newSkills);
  };

  return (
    <div className="form-group row mb-2" data-toggle="tooltip" data-placement="bottom" title={t('Common_FieldSkillsDesc')}>
      <br/>
      <label htmlFor="skillsField" className="col-sm-2 col-form-label fw-bold">{t('Common_FieldSkills')}:</label>
      <div className="col-sm-10">
      {skills.map((skill, index) => (
        <div className="row mb-1" key={index}>
          <div className="col-sm-9">
            <input
              type="text"
              className="form-control col-sm-11"
              id={`skill${index}`}
              name="skill"
              value={skill}
              onChange={(event) => handleChange(index, event)}
            />
          </div>
          <div className="col-sm-3">
            <button type="button" className="btn btn-danger" onClick={() => handleRemoveSkill(index)}>
              {t('Common_ButtonRemove')}
            </button>
          </div>
        </div>
      ))}
      <div className="form-group col-sm-4">
        <button type="button" className="btn btn-success" onClick={addSkill}>
          {t('Common_ButtonAddSkill')}
        </button>
      </div>
      </div>
    </div>
  );
};  

function FormGroupAwards({awards, setAwards}) {
  const { t } = useTranslation();
  const [counter, setCounter] = React.useState(1);

  const addAward = () => {
    setAwards([
      ...awards,
      "",
    ]);
    setCounter(counter + 1);
  };

  const handleRemoveAward = (index) => {
    setAwards(awards.filter((award, i) => i !== index));
  };

  const handleChange = (index, event) => {
    let newAwards = [...awards];
    newAwards[index] = event.target.value;
    setAwards(newAwards);
  };

  return (
    <div className="form-group row mb-2" data-toggle="tooltip" data-placement="bottom" title={t('Common_FieldAwardsDesc')}>
      <br/>
      <label htmlFor="awardsField" className="col-sm-2 col-form-label fw-bold">{t('Common_FieldAwards')}:</label>
      <div className="col-sm-10">
      {awards.map((award, index) => (
        <div className="row mb-1" key={index}>
          <div className="col-sm-9">
            <input
              type="text"
              className="form-control col-sm-11"
              id={`award${index}`}
              name="award"
              value={award}
              onChange={(event) => handleChange(index, event)}
            />
          </div>
          <div className="col-sm-3">
            <button type="button" className="btn btn-danger" onClick={() => handleRemoveAward(index)}>
              {t('Common_ButtonRemove')}
            </button>
          </div>
        </div>
      ))}
      <div className="form-group col-sm-4">
        <button type="button" className="btn btn-success" onClick={addAward}>
          {t('Common_ButtonAddAward')}
        </button>
      </div>
      </div>
    </div>
  );
};

export {
  addAlert,
  FormGroupText,
  FormGroupWorkExperience,
  FormGroupEducation,
  FormGroupSkills,
  FormGroupAwards,
  FormLanguage,
  FormGenDoc,
};
