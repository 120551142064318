import React from "react";
import { useTranslation } from "react-i18next";
import {FormGroupText, FormLanguage, FormGenDoc} from "./generator-common"

function GenerateFormEssay() {
  const [topic, setTopic] = React.useState("");
  const [wordCount, setWordCount] = React.useState("");
  const [tone, setTone] = React.useState("");
  const [keyPoints, setKeyPoints] = React.useState("");
  const [audience, setAudience] = React.useState("");
  const [language, setLanguage] = React.useState("");
  const { t } = useTranslation();

  const requestDict = {
    topic: topic,
    wordCount: wordCount,
    tone: tone,
    keyPoints: keyPoints,
    audience: audience,
    language: language,
  };

  const setValueDict = {
    topic: setTopic,
    wordCount: setWordCount,
    tone: setTone,
    keyPoints: setKeyPoints,
    audience: setAudience,
    language: setLanguage,
  };

  const formGroups = (
    <>
      <FormGroupText value={topic} setValue={setTopic} label={t('Essay_FieldTopic')} tooltip={t('Essay_FieldTopicDesc')}/>
      <FormGroupText value={wordCount} setValue={setWordCount} label={t('Essay_FieldWordCount')} tooltip={t('Essay_FieldWordCountDesc')}/>
      <FormGroupText value={tone} setValue={setTone} label={t('Essay_FieldTone')} tooltip={t('Essay_FieldToneDesc')}/>
      <FormGroupText value={keyPoints} setValue={setKeyPoints} label={t('Essay_FieldKeyPoints')} tooltip={t('Essay_FieldKeyPointsDesc')}/>
      <FormGroupText value={audience} setValue={setAudience} label={t('Essay_FieldAudience')} tooltip={t('Essay_FieldAudienceDesc')}/>
      <FormLanguage language={language} setLanguage={setLanguage} />
    </>
  );

  return <FormGenDoc 
    docType="ESSAY"
    requestDict={requestDict}
    setValueDict={setValueDict}
    ajaxUrl="ajax_essay"
    formTitle={t('Essay_FormTitle')}
    formGroups={formGroups} />;
}

export default GenerateFormEssay;
