import React from "react";
import { useTranslation } from "react-i18next";
import {FormGroupText, FormLanguage, FormGenDoc} from "./generator-common"

function GenerateFormMissionStatement() {
  const [companyOrOrganizationName, setCompanyOrOrganizationName] = React.useState("");
  const [industry, setIndustry] = React.useState("");
  const [targetAudience, setTargetAudience] = React.useState("");
  const [purpose, setPurpose] = React.useState("");
  const [values, setValues] = React.useState("");
  const [language, setLanguage] = React.useState("");
  const { t } = useTranslation();

  const requestDict = {
    companyOrOrganizationName: companyOrOrganizationName,
    industry: industry,
    targetAudience: targetAudience,
    purpose: purpose,
    values: values,
    language: language,
  };

  const setValueDict = {
    companyOrOrganizationName: setCompanyOrOrganizationName,
    industry: setIndustry,
    targetAudience: setTargetAudience,
    purpose: setPurpose,
    values: setValues,
    language: setLanguage,
  };

  const formGroups = (
    <>
      <FormGroupText value={companyOrOrganizationName} setValue={setCompanyOrOrganizationName} label={t('MissionStatement_FieldCompanyOrOrganizationName')} tooltip={t('MissionStatement_FieldCompanyOrOrganizationNameDesc')}/>
      <FormGroupText value={industry} setValue={setIndustry} label={t('MissionStatement_FieldIndustry')} tooltip={t('MissionStatement_FieldIndustryDesc')}/>
      <FormGroupText value={targetAudience} setValue={setTargetAudience} label={t('MissionStatement_FieldTargetAudience')} tooltip={t('MissionStatement_FieldTargetAudienceDesc')}/>
      <FormGroupText value={purpose} setValue={setPurpose} label={t('MissionStatement_FieldPurpose')} tooltip={t('MissionStatement_FieldPurposeDesc')}/>
      <FormGroupText value={values} setValue={setValues} label={t('MissionStatement_FieldValues')} tooltip={t('MissionStatement_FieldValuesDesc')}/>
      <FormLanguage language={language} setLanguage={setLanguage} />
    </>
  );

  return <FormGenDoc 
    docType="MISSION_STATEMENT"
    requestDict={requestDict}
    setValueDict={setValueDict}
    ajaxUrl="ajax_mission_statement"
    formTitle={t('MissionStatement_FormTitle')}
    formGroups={formGroups} />;
}

export default GenerateFormMissionStatement;
