import React from "react";
import { useTranslation } from "react-i18next";
import {FormGroupText, FormLanguage, FormGenDoc} from "./generator-common"

function GenerateFormGeneralEngineeringDesign() {
  const [projectRequirements, setProjectRequirements] = React.useState("");
  const [constraints, setConstraints] = React.useState("");
  const [designParameters, setDesignParameters] = React.useState("");
  const [previousDesigns, setPreviousDesigns] = React.useState("");
  const [language, setLanguage] = React.useState("");
  const { t } = useTranslation();

  const requestDict = {
    projectRequirements: projectRequirements,
    constraints: constraints,
    designParameters: designParameters,
    previousDesigns: previousDesigns,
    language: language,
  };

  const setValueDict = {
    projectRequirements: setProjectRequirements,
    constraints: setConstraints,
    designParameters: setDesignParameters,
    previousDesigns: setPreviousDesigns,
    language: setLanguage,
  };

  const formGroups = (
    <>
      <FormGroupText value={projectRequirements} setValue={setProjectRequirements} label={t('GeneralEngineeringDesign_FieldProjectRequirements')} tooltip={t('GeneralEngineeringDesign_FieldProjectRequirementsDesc')}/>
      <FormGroupText value={constraints} setValue={setConstraints} label={t('GeneralEngineeringDesign_FieldConstraints')} tooltip={t('GeneralEngineeringDesign_FieldConstraintsDesc')}/>
      <FormGroupText value={designParameters} setValue={setDesignParameters} label={t('GeneralEngineeringDesign_FieldDesignParameters')} tooltip={t('GeneralEngineeringDesign_FieldDesignParametersDesc')}/>
      <FormGroupText value={previousDesigns} setValue={setPreviousDesigns} label={t('GeneralEngineeringDesign_FieldPreviousDesigns')} tooltip={t('GeneralEngineeringDesign_FieldPreviousDesignsDesc')}/>
      <FormLanguage language={language} setLanguage={setLanguage} />
    </>
  );

  return <FormGenDoc 
    docType="GENERAL_ENGINEERING_DESIGN"
    requestDict={requestDict}
    setValueDict={setValueDict}
    ajaxUrl="ajax_general_engineering_design"
    formTitle={t('GeneralEngineeringDesign_FormTitle')}
    formGroups={formGroups} />;
}

export default GenerateFormGeneralEngineeringDesign;
