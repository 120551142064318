import React from "react";
import Cookies from 'universal-cookie';

function makeAjaxRawRequest(ajaxUrl, ajaxRequest, callback, setErrorMessage=undefined, setIsLoading=undefined) {
  if (setErrorMessage) setErrorMessage("");
  if (setIsLoading) setIsLoading(true);

  const cookies = new Cookies();
  const csrftoken = cookies.get('csrftoken');

  const backendAjaxUrl = process.env.REACT_APP_BACKEND_API_URL + ajaxUrl;
  const request = new Request(backendAjaxUrl, {
    method: "POST",
    headers: {'X-CSRFToken': csrftoken},
    body: ajaxRequest,
    credentials: 'include',
  });

  fetch(request)
    .then((res) => {
      if (setIsLoading) setIsLoading(false);

      if (!res.ok) {
        throw new Error(`Network response was ${res.status}: ${res.statusText}`);
      }
      return res.json();
    })
    .then(
      (result) => {
        if (result["hasError"]) {
          console.log(result["errorMessage"]);
          if (setErrorMessage) setErrorMessage(result["errorMessage"]);
        }
        else {
          callback(result);
        }
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        console.log(error);
        if (setErrorMessage) setErrorMessage("Unexpected Error Happened: " + error.toString());
      }
    );
};

function makeAjaxRequest(ajaxUrl, ajaxJsonRequest, callback, setErrorMessage=undefined, setIsLoading=undefined) {
  makeAjaxRawRequest(ajaxUrl, JSON.stringify(ajaxJsonRequest), callback, setErrorMessage, setIsLoading);
};

export {
  makeAjaxRequest,
  makeAjaxRawRequest,
};
