import React from "react";
import { useTranslation } from "react-i18next";
import {FormGroupText, FormLanguage, FormGenDoc} from "./generator-common"

function GenerateFormResearchPaper() {
  const [topic, setTopic] = React.useState("");
  const [researchQuestions, setResearchQuestions] = React.useState("");
  const [hypothesis, setHypothesis] = React.useState("");
  const [researchMethodology, setResearchMethodology] = React.useState("");
  const [literatureReview, setLiteratureReview] = React.useState("");
  const [results, setResults] = React.useState("");
  const [conclusion, setConclusion] = React.useState("");
  const [language, setLanguage] = React.useState("");
  const { t } = useTranslation();

  const requestDict = {
    topic: topic,
    researchQuestions: researchQuestions,
    hypothesis: hypothesis,
    researchMethodology: researchMethodology,
    literatureReview: literatureReview,
    results: results,
    conclusion: conclusion,
    language: language,
  };

  const setValueDict = {
    topic: setTopic,
    researchQuestions: setResearchQuestions,
    hypothesis: setHypothesis,
    researchMethodology: setResearchMethodology,
    literatureReview: setLiteratureReview,
    results: setResults,
    conclusion: setConclusion,
    language: setLanguage,
  };

  const formGroups = (
    <>
      <FormGroupText value={topic} setValue={setTopic} label={t('ResearchPaper_FieldTopic')} tooltip={t('ResearchPaper_FieldTopicDesc')}/>
      <FormGroupText value={researchQuestions} setValue={setResearchQuestions} label={t('ResearchPaper_FieldResearchQuestions')} tooltip={t('ResearchPaper_FieldResearchQuestionsDesc')}/>
      <FormGroupText value={hypothesis} setValue={setHypothesis} label={t('ResearchPaper_FieldHypothesis')} tooltip={t('ResearchPaper_FieldHypothesisDesc')}/>
      <FormGroupText value={researchMethodology} setValue={setResearchMethodology} label={t('ResearchPaper_FieldResearchMethodology')} tooltip={t('ResearchPaper_FieldResearchMethodologyDesc')}/>
      <FormGroupText value={literatureReview} setValue={setLiteratureReview} label={t('ResearchPaper_FieldLiteratureReview')} tooltip={t('ResearchPaper_FieldLiteratureReviewDesc')}/>
      <FormGroupText value={results} setValue={setResults} label={t('ResearchPaper_FieldResults')} tooltip={t('ResearchPaper_FieldResultsDesc')}/>
      <FormGroupText value={conclusion} setValue={setConclusion} label={t('ResearchPaper_FieldConclusion')} tooltip={t('ResearchPaper_FieldConclusionDesc')}/>
      <FormLanguage language={language} setLanguage={setLanguage} />
    </>
  );

  return <FormGenDoc 
    docType="RESEARCH_PAPER"
    requestDict={requestDict}
    setValueDict={setValueDict}
    ajaxUrl="ajax_research_paper"
    formTitle={t('ResearchPaper_FormTitle')}
    formGroups={formGroups} />;
}

export default GenerateFormResearchPaper;
