import React from "react";
import { useTranslation } from "react-i18next";
import {FormGroupText, FormLanguage, FormGenDoc} from "./generator-common"

function GenerateFormPoetry() {
  const [poemType, setPoemType] = React.useState("");
  const [theme, setTheme] = React.useState("");
  const [rhymeScheme, setRhymeScheme] = React.useState("");
  const [tone, setTone] = React.useState("");
  const [length, setLength] = React.useState("");
  const [language, setLanguage] = React.useState("");
  const { t } = useTranslation();

  const requestDict = {
    poemType: poemType,
    theme: theme,
    rhymeScheme: rhymeScheme,
    tone: tone,
    length: length,
    language: language,
  };

  const setValueDict = {
    poemType: setPoemType,
    theme: setTheme,
    rhymeScheme: setRhymeScheme,
    tone: setTone,
    length: setLength,
    language: setLanguage,
  };

  const formGroups = (
    <>
      <FormGroupText value={poemType} setValue={setPoemType} label={t('Poetry_FieldPoemType')} tooltip={t('Poetry_FieldPoemTypeDesc')}/>
      <FormGroupText value={theme} setValue={setTheme} label={t('Poetry_FieldTheme')} tooltip={t('Poetry_FieldThemeDesc')}/>
      <FormGroupText value={rhymeScheme} setValue={setRhymeScheme} label={t('Poetry_FieldRhymeScheme')} tooltip={t('Poetry_FieldRhymeSchemeDesc')}/>
      <FormGroupText value={tone} setValue={setTone} label={t('Poetry_FieldTone')} tooltip={t('Poetry_FieldToneDesc')}/>
      <FormGroupText value={length} setValue={setLength} label={t('Poetry_FieldLength')} tooltip={t('Poetry_FieldLengthDesc')}/>
      <FormLanguage language={language} setLanguage={setLanguage} />
    </>
  );

  return <FormGenDoc 
    docType="POETRY"
    requestDict={requestDict}
    setValueDict={setValueDict}
    ajaxUrl="ajax_poetry"
    formTitle={t('Poetry_FormTitle')}
    formGroups={formGroups} />;
}

export default GenerateFormPoetry;
