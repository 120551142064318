import React from 'react';
import { useTranslation } from "react-i18next";
import { makeAjaxRequest } from "../common.js";

function SignupModal() {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [password2, setPassword2] = React.useState('');
  const [username, setUsername] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [finishSignup, setFinishSignup] = React.useState(false);
  const { t } = useTranslation();

  const handleSubmit = (event) => {
    event.preventDefault();

    const userInfoJson = {
      email: email,
      password: password,
      password2: password2,
      username: username,
    };
    const callback = (result) => {
      setFinishSignup(true);
    }
    makeAjaxRequest("/sa/signup_ajax", userInfoJson, callback, setErrorMessage, setIsLoading);
  };

  return (
  <div className="modal fade" id="signupModal" tabIndex="-1" aria-labelledby="signupModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content rounded-4 shadow">
      <div className="modal-header p-5 pb-4 border-bottom-0">
        <h1 className="fw-bold mb-0 fs-2">{t('Signup_FormTitle')}</h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>

      <div className="modal-body p-5 pt-0">
        {finishSignup ? (<div>{t('Signup_FinishSignup')}</div>) : (
        <form onSubmit={handleSubmit}>
          <div className="form-floating mb-3">
            <input type="text" className="form-control rounded-3" id="signupUsername" required value={username} 
             placeholder="Username" onChange={(event) => setUsername(event.target.value)} />
            <label htmlFor="signupUsername">{t('Common_FieldUsername')}</label>
          </div>
          <div className="form-floating mb-3">
            <input type="email" className="form-control rounded-3" id="signupEmail" required value={email}
             placeholder="name@example.com" onChange={(event) => setEmail(event.target.value)} />
            <label htmlFor="signupEmail">{t('Common_FieldEmail')}</label>
          </div>
          <div className="form-floating mb-3">
            <input type="password" className="form-control rounded-3" id="signupPassword" required value={password}
             placeholder="Password" onChange={(event) => setPassword(event.target.value)} />
            <label htmlFor="signupPassword">{t('Common_FieldPassword')}</label>
          </div>
          <div className="form-floating mb-3">
            <input type="password" className="form-control rounded-3" id="signupPassword2" required value={password2}
             placeholder="Password" onChange={(event) => setPassword2(event.target.value)} />
            <label htmlFor="signupPassword2">{t('Common_FieldConfirmPassword')}</label>
          </div>
          <button className="w-100 mb-2 btn btn-lg rounded-3 btn-primary" type="submit">{t('Common_ButtonSignup')}</button>
        </form>
          )
        }

        {errorMessage &&
        <div className="alert alert-danger" role="alert">{errorMessage}</div>
        }
        {isLoading &&
        <div id="loading-root" className="spinner-border text-primary" role="status"></div>
        }
      </div>
    </div>  
  </div>
  </div>
  );
};

export default SignupModal;
