import React from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import { makeAjaxRequest } from "../common.js";
import './sa.css';


function VerifyEmailResult({isLoading, errorMessage}) {
  const { t } = useTranslation();

  if (isLoading)
    return (<div id="loading-root" className="spinner-border text-primary" role="status"></div>);
  if (errorMessage)
    return (<div className="alert alert-danger" role="alert">{errorMessage}</div>);
  return (
    <>
      <p>
        {t('EmailVerify_Message')}
      </p>
      <div className="mt-4">
        <a href="/" className="btn btn-primary">
          {t('EmailVerify_HomePage')}
        </a>
      </div>
    </>
  );
}

function VerifyEmail() {
  const { uidb64, token } = useParams();
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    const userInfoJson = {
      uidb64: uidb64,
      token: token,
    };
    const callback = (result) => {
      const timer = setTimeout(() => {
        window.location.href = '/';
      }, 5000);

      return () => clearTimeout(timer);
    }
    makeAjaxRequest("/sa/ajax_verify_email", userInfoJson, callback, setErrorMessage, setIsLoading);

  }, [uidb64, token]);

  return (
    <div className="container email-verification-confirmation">
      <div className="row justify-content-center align-items-center min-vh-100">
        <div className="col-xs-12 col-md-6">
          <div className="text-center">
            <h1>{t('EmailVerify_Title')}</h1>
            <VerifyEmailResult isLoading={isLoading} errorMessage={errorMessage} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerifyEmail;
