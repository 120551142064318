import React from "react";
import { useTranslation } from "react-i18next";
import {FormGroupText, FormLanguage, FormGenDoc} from "./generator-common"

function GenerateFormBiography() {
  const [name, setName] = React.useState("");
  const [birthAndEarlyLife, setBirthAndEarlyLife] = React.useState("");
  const [educationAndCareer, setEducationAndCareer] = React.useState("");
  const [personalLife, setPersonalLife] = React.useState("");
  const [legacy, setLegacy] = React.useState("");
  const [language, setLanguage] = React.useState("");
  const { t } = useTranslation();

  const requestDict = {
    name: name,
    birthAndEarlyLife: birthAndEarlyLife,
    educationAndCareer: educationAndCareer,
    personalLife: personalLife,
    legacy: legacy,
    language: language,
  };

  const setValueDict = {
    name: setName,
    birthAndEarlyLife: setBirthAndEarlyLife,
    educationAndCareer: setEducationAndCareer,
    personalLife: setPersonalLife,
    legacy: setLegacy,
    language: setLanguage,
  };

  const formGroups = (
    <>
      <FormGroupText value={name} setValue={setName} label={t('Biography_FieldName')} tooltip={t('Biography_FieldNameDesc')}/>
      <FormGroupText value={birthAndEarlyLife} setValue={setBirthAndEarlyLife} label={t('Biography_FieldBirthAndEarlyLife')} tooltip={t('Biography_FieldBirthAndEarlyLifeDesc')}/>
      <FormGroupText value={educationAndCareer} setValue={setEducationAndCareer} label={t('Biography_FieldEducationAndCareer')} tooltip={t('Biography_FieldEducationAndCareerDesc')}/>
      <FormGroupText value={personalLife} setValue={setPersonalLife} label={t('Biography_FieldPersonalLife')} tooltip={t('Biography_FieldPersonalLifeDesc')}/>
      <FormGroupText value={legacy} setValue={setLegacy} label={t('Biography_FieldLegacy')} tooltip={t('Biography_FieldLegacyDesc')}/>
      <FormLanguage language={language} setLanguage={setLanguage} />
    </>
  );

  return <FormGenDoc 
    docType="BIOGRAPHY"
    requestDict={requestDict}
    setValueDict={setValueDict}
    ajaxUrl="ajax_biography"
    formTitle={t('Biography_FormTitle')}
    formGroups={formGroups} />;
}

export default GenerateFormBiography;
